import { graphql } from "react-relay";

export const robotUserResetTokenMutation = graphql`
  mutation RobotUserResetTokenMutation($id: UUID!) {
    robotUserResetToken(tokenReset: { userUid: $id }) {
      token
      ...PayloadMessages_interface
    }
  }
`;
