/**
 * @generated SignedSource<<6bcfe411f942dae477e8a053c0b08f5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserRightsResource_resource$data = ReadonlyArray<{
  readonly actions: ReadonlyArray<{
    readonly action: string;
    readonly description: string;
    readonly systemRoles: ReadonlyArray<SystemRoles>;
    readonly title: string;
  }>;
  readonly description: string;
  readonly namespace: string;
  readonly resource: string;
  readonly title: string;
  readonly " $fragmentType": "UserRightsResource_resource";
}>;
export type UserRightsResource_resource$key = ReadonlyArray<{
  readonly " $data"?: UserRightsResource_resource$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserRightsResource_resource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namespace",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resource",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRightsAction",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemRoles",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserRightsResource",
  "abstractKey": null
};
})();

(node as any).hash = "b062535362f800abb4f5b55e3f7e4a60";

export default node;
