import * as yup from "yup";
import { DiscountsQuery } from "@relay-generated/DiscountsQuery.graphql";
import { DiscountUpdate } from "@relay-generated/DiscountUpdateMutation.graphql";
import { DiscountInsert } from "@relay-generated/DiscountInsertMutation.graphql";

// FIXME NonNullable vyhodit a ošetřit správně
export type DiscountTableItem = NonNullable<
  DiscountsQuery["response"]["discountList"]
>[number];
export type DiscountFormData = Partial<DiscountUpdate & DiscountInsert>;

export const discountBaseSchema = yup.object({
  name: yup.string().required(),
  value: yup.number().required().min(1).max(100),
  // FIXME
});
