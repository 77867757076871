import { ReactNode, useEffect, useMemo, useRef } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { Loading } from "h11-client-component-lib";
import { useDispatch, useSelector } from "react-redux";
import {
  setChainHotelsList,
  setCurrentHotel,
  setLoggedUser,
} from "@store/localSessionSlice";
import { LocalSessionQuery } from "@relay-generated/LocalSessionQuery.graphql";
import { localSessionQuery } from "@shared/graphql/localSession/LocalSessionQuery";
import { CurrentUserFragment$key } from "@relay-generated/CurrentUserFragment.graphql";
import { currentUserFragment } from "@shared/graphql/localSession/CurrentUserFragment";
import { readInlineData } from "relay-runtime";
import { RootState } from "@store";
import { useDomainHotelInfo } from "./DomainHotelInfoContext";
import { useTranslation } from "react-i18next";

function LocalSessionProviderContent({
  children,
  queryRef,
}: {
  children: ReactNode;
  queryRef: PreloadedQuery<LocalSessionQuery>;
}) {
  const localSessionGet = usePreloadedQuery<LocalSessionQuery>(
    localSessionQuery,
    queryRef,
  )!;

  const domainHotelInfo = useDomainHotelInfo();
  const { i18n } = useTranslation();

  const user = useMemo(
    () =>
      readInlineData<CurrentUserFragment$key>(
        currentUserFragment,
        localSessionGet.userCurrentGet!,
      ),
    [localSessionGet],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoggedUser(user));

    i18n.changeLanguage(user.lang ?? "en");

    const chainHotels = [...localSessionGet.hotelChainHotelsList];

    // TODO vyřešit, když aktuální hotel není v tomto seznamu
    dispatch(setChainHotelsList(chainHotels));

    const currentHotel =
      chainHotels.find(h => h.code === domainHotelInfo.hotelCode) ?? null;

    dispatch(setCurrentHotel(currentHotel));
  }, [user, window.location.host]);

  return children;
}

export function LocalSessionLoader({ children }: { children: ReactNode }) {
  const [queryRef, loadQuery] =
    useQueryLoader<LocalSessionQuery>(localSessionQuery);

  const userLoadSeq = useSelector(
    (state: RootState) => state.localSession.loadSeq,
  );

  const mounted = useRef(0);

  useEffect(() => {
    if (mounted.current !== userLoadSeq) {
      mounted.current = userLoadSeq;
      loadQuery({}, { fetchPolicy: "store-and-network" });
    }

    return () => {
      mounted.current = 0;
    };
  }, [userLoadSeq]);

  return queryRef ? (
    <LocalSessionProviderContent queryRef={queryRef}>
      {children}
    </LocalSessionProviderContent>
  ) : (
    // TODO přidat nějaký text redirecting to login page a případné kliknutí, kdyby nešlo?
    <div
      style={{
        gridColumn: "1/3",
        gridRow: "1/3",
        alignItems: "center",
        height: "100%",
      }}>
      <Loading size={300} centerVertical />
    </div>
  );
}
