import { graphql } from "react-relay";

export const userDeleteAvatarMutation = graphql`
  mutation UserDeleteAvatarMutation($userUid: UUID!) {
    userDeleteAvatar(userUid: $userUid) {
      deleted
      ...PayloadMessages_interface
    }
  }
`;
