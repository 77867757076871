import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentUserFragment$data } from "@relay-generated/CurrentUserFragment.graphql";
import { LocalSessionQuery } from "@relay-generated/LocalSessionQuery.graphql";

export type CurrentUserDetail = CurrentUserFragment$data;
export type ChainHotel =
  LocalSessionQuery["response"]["hotelChainHotelsList"][0];

export interface LocalSessionState {
  loggedUser?: CurrentUserDetail;
  chainHotelsList?: ChainHotel[];
  currentHotel?: ChainHotel | null;

  // loadSeq is used for currentUser refresh - if the number is higher than the last one, LoggedUserProvider reloads current user
  loadSeq: number;
}

const initialState: LocalSessionState = {
  loadSeq: 1,
};

const localSessionSlice = createSlice({
  name: "local-session",
  initialState,
  reducers: {
    setLoggedUser(state, action: PayloadAction<CurrentUserDetail>) {
      state.loggedUser = action.payload;
    },
    setChainHotelsList(state, action: PayloadAction<ChainHotel[]>) {
      state.chainHotelsList = action.payload;
    },
    setCurrentHotel(state, action: PayloadAction<ChainHotel | null>) {
      state.currentHotel = action.payload;
    },
    refreshLoggedUser(state) {
      ++state.loadSeq;
    },
  },
});

export const {
  setLoggedUser,
  setChainHotelsList,
  setCurrentHotel,
  refreshLoggedUser,
} = localSessionSlice.actions;
export const reducer = localSessionSlice.reducer;
