import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { InferType, ObjectSchema } from "yup";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { useRelayEnvironment } from "react-relay";
import { CallError, ChainResponsesTypeBase, ExecuteResult } from "@comm/comm";
import { useAppContext } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";

export function useApiForm<
  DataType extends object,
  SchemaType extends ObjectSchema<object>,
  ChainResponsesType extends ChainResponsesTypeBase = never,
>({
  initialValues = {},
  schema,
  messages,
  visible = true,
  onSubmit,
  onSuccess,
}: {
  initialValues?: Partial<DataType>;
  schema: SchemaType;

  // Visible acts as a dependency for form reset but also other things that makes sense only when the form is visible
  // (ie. fields error logging).
  // Default is visible.
  visible?: boolean;
  messages?: {
    success?: string;
    withWarnings?: string;
    unexpectedError?: string;
  };
  onSubmit: (
    values: InferType<typeof schema>,
    relayEnvironment: RelayModernEnvironment,
  ) => Promise<ExecuteResult<ChainResponsesType>>;
  onSuccess: (responses: Partial<ChainResponsesType>) => void;
}) {
  const { notify } = useAppContext();
  const { t } = useTranslation();
  const relayEnvironment = useRelayEnvironment();
  const [submitting, setSubmitting] = useState(false);
  const formik = useFormik<Partial<DataType>>({
    initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: async values => {
      setSubmitting(true);

      const validValues = schema.validateSync(values, {
        stripUnknown: true,
      });

      try {
        const { warnings, responses } = await onSubmit(
          validValues,
          relayEnvironment,
        );
        if (warnings) {
          notify(messages?.withWarnings ?? t("saved_with_errors"), "warning");
        } else {
          notify(messages?.success ?? t("saved"), "success");
        }

        onSuccess(responses);
      } catch (e) {
        if (e instanceof CallError) {
          if (e.type === "expected") {
            notify(e.message, "danger");
            return;
          }
        }

        console.error(e);

        notify(messages?.unexpectedError ?? t("unexpected_error"), "danger");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const lastSubmitCount = useRef(0);

  useEffect(() => {
    if (
      Object.keys(formik.errors).length &&
      visible &&
      formik.submitCount > lastSubmitCount.current
    ) {
      lastSubmitCount.current = formik.submitCount;
      console.warn(formik.errors);
    }
  }, [formik.submitCount]);

  useEffect(() => {
    if (visible) {
      formik.resetForm();
      formik.setValues(initialValues, true);
    }
  }, [visible]);

  return { formik, submitting };
}
