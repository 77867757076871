/**
 * @generated SignedSource<<2aec32727cc1c238c751465755686620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDeleteAvatarMutation$variables = {
  userUid: string;
};
export type UserDeleteAvatarMutation$data = {
  readonly userDeleteAvatar: {
    readonly deleted: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
  };
};
export type UserDeleteAvatarMutation = {
  response: UserDeleteAvatarMutation$data;
  variables: UserDeleteAvatarMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userUid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userUid",
    "variableName": "userUid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "PayloadMessages_message",
    "selections": (v3/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDeleteAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoundAssetDeletePayload",
        "kind": "LinkedField",
        "name": "userDeleteAvatar",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "PayloadMessages_interface",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "warnings",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "PayloadInterface",
                "abstractKey": "__isPayloadInterface"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserDeleteAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoundAssetDeletePayload",
        "kind": "LinkedField",
        "name": "userDeleteAvatar",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "type": "PayloadInterface",
            "abstractKey": "__isPayloadInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f1a68eaaef26a9f14b4dd3e8eba4bb6",
    "id": null,
    "metadata": {},
    "name": "UserDeleteAvatarMutation",
    "operationKind": "mutation",
    "text": "mutation UserDeleteAvatarMutation(\n  $userUid: UUID!\n) {\n  userDeleteAvatar(userUid: $userUid) {\n    deleted\n    ...PayloadMessages_interface\n  }\n}\n\nfragment PayloadMessages_interface on PayloadInterface {\n  __isPayloadInterface: __typename\n  errors {\n    ...PayloadMessages_message\n  }\n  warnings {\n    ...PayloadMessages_message\n  }\n}\n\nfragment PayloadMessages_message on PayloadMessage {\n  code\n  text\n}\n"
  }
};
})();

(node as any).hash = "03e0ebeac232e68dd69d9165d3bec17d";

export default node;
