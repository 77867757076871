import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { useEffect } from "react";
import { FormDialog } from "h11-client-component-lib";
import { discountBaseSchema, DiscountFormData } from "../discountData";
import { DiscountForm } from "../DiscountForm";
import { useApiForm } from "@shared/forms/apiForm";

export function DiscountBaseDialog<TSchema extends typeof discountBaseSchema>({
  open,
  onClose,
  schema,
  initialValues,
  title,
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  schema: TSchema;
  initialValues: DiscountFormData;
  title: string;
}) {
  const { t } = useTranslation();

  const { formik } = useApiForm<DiscountFormData, TSchema, { dummy: null }>({
    initialValues,
    schema,
    messages: {
      success: t("discount_saved"),
    },
    onSubmit: (values, relayEnvironment) => {
      return Promise.resolve({ responses: { dummy: null } });
      /*return prepareApiCall<TMutation, never>({
        mutation: mutation,
        variables: mutationVariablesExtractor(values),
        environment: relayEnvironment,
      }).execute();*/
    },
    visible: open,
    onSuccess: () => {
      onClose(true);
    },
  });

  console.log(formik.errors);

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues(initialValues, true);
    }
  }, [open]);

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose(false)}
        title={title}
        variant="narrow"
        centered>
        <DiscountForm />
      </FormDialog>
    </FormikProvider>
  );
}
