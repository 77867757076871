import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  TextField,
  useAppContext,
} from "h11-client-component-lib";
import "./CopyPromptDialog.scss";
import { Stickies } from "react-bootstrap-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const CopyPromptDialog = ({
  open,
  text,
  title,
  fieldLabel,
  message,
  onClose,
}: {
  open: boolean;
  title: string;
  fieldLabel: string;
  text: string;
  message?: ReactElement;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { notify } = useAppContext();
  return (
    <Dialog
      open={open}
      className="CopyPromptDialog"
      centered
      title={title}
      variant="narrow"
      onClose={() => {}}
      actions={
        <Button variant="secondary" onClick={() => onClose()}>
          {t("close")}
        </Button>
      }>
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          alignItems: "flex-start",
        }}>
        {message}
        <TextField
          disabled
          style={{ alignSelf: "stretch" }}
          label={fieldLabel}
          value={text}
          onChange={() => {}}
        />
        <CopyToClipboard
          text={text}
          onCopy={() => notify(t("copied_to_clipboard"), "success")}>
          <Button icon={<Stickies />} variant="secondary">
            {t("copy_to_clipboard")}
          </Button>
        </CopyToClipboard>
      </div>
    </Dialog>
  );
};
