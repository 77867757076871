import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { reservationSourceBaseSchema } from "../reservationSourceData";
import { ReservationSourceBaseDialog } from "./ReservationSourceBaseDialog";
import { reservationSourceUpdateDialogQuery } from "../graphql/ReservationSourceUpdateDialogQuery";
import { ReservationSourceUpdateDialogQuery } from "@relay-generated/ReservationSourceUpdateDialogQuery.graphql";

export function ReservationSourceUpdateDialog({
  queryRef,
  ...restProps
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  queryRef: PreloadedQuery<ReservationSourceUpdateDialogQuery>;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(reservationSourceUpdateDialogQuery, queryRef);

  return (
    <ReservationSourceBaseDialog
      {...restProps}
      title={t("reservation_source_update")}
      data={data}
      initialValues={{}}
      schema={reservationSourceBaseSchema}
    />
  );
}
