import { FileUploadRequest } from "@relay-generated/UserSetAvatarMutation.graphql";

export function splitFilename(input: File | string): {
  basename: string;
  extension: string;
} {
  let name: string;
  let extension: string;

  if (input instanceof File) {
    // If our input is a File object
    [name, extension] = input.name.split(/\.(?=[^.]+$)/);
  } else {
    // If our input is a string
    [name, extension] = input.split(/\.(?=[^.]+$)/);
  }

  return {
    basename: name,
    extension: extension,
  };
}

export function createFileUploadParameters(file: File): FileUploadRequest {
  return {
    fileExtension: splitFilename(file).extension,
    fileSize: file.size,
  };
}
