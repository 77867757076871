import { configureStore } from "@reduxjs/toolkit";
import {
  CurrentUserDetail,
  reducer as localSessionReducer,
} from "./localSessionSlice";
import { useSelector } from "react-redux";
import { useDomainHotelInfo } from "../DomainHotelInfoContext";

export const store = configureStore({
  reducer: {
    localSession: localSessionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// TODO nejde type inference?
export const useLoggedUser: () => CurrentUserDetail | undefined = () => {
  return useSelector((state: RootState) => state.localSession.loggedUser);
};

export const useOAuthLoginRedirect = () => {
  const oAuthLoginLink = useDomainHotelInfo().oAuthLoginUrl;
  return () => {
    if (oAuthLoginLink) {
      window.location.href = oAuthLoginLink;
    }
  };
};
