import { graphql } from "react-relay";

export const currentUserFragment = graphql`
  fragment CurrentUserFragment on User @inline {
    userUid
    userName
    firstName
    lastName
    chainUser
    code
    lang
    hotelTel
    hotelEmail
    hotelSignature
    avatar {
      url
    }
  }
`;
