import { useContext } from "react";
import { useReservationsColumns } from "./reservationColumns";
import { TableContextFooter } from "./TableContextFooter";
import { ReservationsContext } from "./ReservationsPage";
import { Table, useFilterAndHighlight } from "h11-client-component-lib";
import { ReservationsQuery } from "@relay-generated/ReservationsQuery.graphql";
import { HighlightedReservationTableItem } from "./reservationsData";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { reservationsQuery } from "./graphql/ReservationsQuery";

export function ReservationsTable({
  queryRef,
  search,
}: {
  queryRef: PreloadedQuery<ReservationsQuery>;
  search: string;
}) {
  const reservationsContext = useContext(ReservationsContext);

  const data = usePreloadedQuery(reservationsQuery, queryRef);

  const filteredData = useFilterAndHighlight(
    data.reservationsOverviewList.edges.map(e => e.node),
    search,
    "name",
    "number",
    "orderNumber",
  ); // FIXME reference number v #HSF-111? Co to je? Transaction number asi ne, protože search tam nefunguje

  const columns = useReservationsColumns(data);

  return (
    // TODO make "bloat" more general or separate the page more nicely
    reservationsContext && (
      <Table<HighlightedReservationTableItem>
        tableId="reservations"
        className="reservations-table bloat"
        columns={columns}
        data={filteredData}
        columnFilters={reservationsContext.columnFilters}
        onColumnFiltersChange={reservationsContext.setColumnFilters}
        selection={reservationsContext.selection}
        onSelectionChange={reservationsContext.setSelection}
        renderContextFooter={item => <TableContextFooter />}
        trProps={d =>
          d.status === "CANCELLED" ? { className: "cancelled" } : {}
        }
      />
    )
  );
}
