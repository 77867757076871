/**
 * @generated SignedSource<<dff273da0c721d0e416a5b4749492872>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Order = "ASC" | "DESC" | "%future added value";
export type ReservationGroups = "GROUP" | "SINGLE" | "%future added value";
export type ReservationPrepayment = "NO_PREPAYMENT" | "PAID" | "PROFORMA" | "PROFORMA_OVERDUE" | "UNPAID" | "%future added value";
export type ReservationRefundable = "NON_REFUNDABLE" | "REFUNDABLE" | "%future added value";
export type ReservationRooming = "EMPTY" | "FILLED" | "INCOMPLETE" | "%future added value";
export type ReservationStatus = "CANCELLED" | "FIX" | "TENTATIVE" | "UNCONFIRMED" | "WAITING" | "%future added value";
export type ReservationsOverviewColumns = "CANCELLED_AT" | "CHECK_IN" | "CHECK_OUT" | "COUNTRY" | "CREATED_AT" | "GROUP" | "NAME" | "NON_REFUNDABLE" | "NOTE" | "NUMBER" | "OPTION" | "ORDER_NUMBER" | "PAYMENT_METHOD" | "PREPAYMENT" | "ROOMING" | "ROOM_TYPE" | "SOURCE" | "STATUS" | "TRANSACTION_NUMBER" | "%future added value";
export type ReservationsOverviewFilters = {
  author?: ReadonlyArray<string> | null | undefined;
  cancelledAt?: any | null | undefined;
  checkIn?: any | null | undefined;
  checkOut?: any | null | undefined;
  createdAt?: any | null | undefined;
  groups?: ReservationGroups | null | undefined;
  guestCode?: ReadonlyArray<number> | null | undefined;
  nonRefundable?: ReadonlyArray<ReservationRefundable> | null | undefined;
  optionAt?: any | null | undefined;
  paymentMethod?: ReadonlyArray<number> | null | undefined;
  prepayment?: ReadonlyArray<ReservationPrepayment> | null | undefined;
  roomTypes?: ReadonlyArray<number> | null | undefined;
  rooming?: ReadonlyArray<ReservationRooming> | null | undefined;
  salesCode?: ReadonlyArray<number> | null | undefined;
  search?: string | null | undefined;
  source?: ReadonlyArray<number> | null | undefined;
  status?: ReadonlyArray<ReservationStatus> | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
};
export type ReservationsOverviewOrderBy = {
  column: ReservationsOverviewColumns;
  order: Order;
};
export type ReservationsQuery$variables = {
  filters?: ReservationsOverviewFilters | null | undefined;
  orderBy?: ReservationsOverviewOrderBy | null | undefined;
};
export type ReservationsQuery$data = {
  readonly reservationSourceList: ReadonlyArray<{
    readonly _id: number;
    readonly name: any;
  }>;
  readonly reservationsOverviewList: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly attachment: string;
        readonly author: string;
        readonly cancelledAt: string | null | undefined;
        readonly checkIn: string;
        readonly checkOut: string;
        readonly country: string | null | undefined;
        readonly createdAt: string;
        readonly group: ReservationGroups;
        readonly guestCode: string | null | undefined;
        readonly guestsCount: number;
        readonly id: any;
        readonly name: string;
        readonly nonRefundable: boolean;
        readonly note: string | null | undefined;
        readonly number: string;
        readonly optionDate: string | null | undefined;
        readonly orderNumber: string | null | undefined;
        readonly paymentMethod: string | null | undefined;
        readonly prepayment: string;
        readonly price: string;
        readonly roomCount: number;
        readonly roomType: string;
        readonly rooming: ReservationRooming;
        readonly salesCode: string | null | undefined;
        readonly source: string;
        readonly status: ReservationStatus;
        readonly tags: ReadonlyArray<number>;
        readonly transactionNumber: string | null | undefined;
      };
    }>;
  };
  readonly tagList: ReadonlyArray<{
    readonly _id: number;
    readonly color: string | null | undefined;
    readonly title: string;
  }> | null | undefined;
  readonly userList: ReadonlyArray<{
    readonly avatar: {
      readonly url: string;
    } | null | undefined;
    readonly firstName: string | null | undefined;
    readonly lastName: string | null | undefined;
    readonly userUid: string;
  }>;
};
export type ReservationsQuery = {
  response: ReservationsQuery$data;
  variables: ReservationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userUid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ReservationSource",
    "kind": "LinkedField",
    "name": "reservationSourceList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "tagList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "ReservationsOverviewHoresConnection",
    "kind": "LinkedField",
    "name": "reservationsOverviewList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReservationsOverviewEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReservationsOverview",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "group",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roomType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roomCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "guestsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkIn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkOut",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prepayment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rooming",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "optionDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "guestCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "salesCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nonRefundable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "author",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancelledAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attachment",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReservationsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9e664029d226679dd0452af93ebb8f21",
    "id": null,
    "metadata": {},
    "name": "ReservationsQuery",
    "operationKind": "query",
    "text": "query ReservationsQuery(\n  $filters: ReservationsOverviewFilters\n  $orderBy: ReservationsOverviewOrderBy\n) {\n  userList {\n    userUid\n    firstName\n    lastName\n    avatar {\n      url\n    }\n  }\n  reservationSourceList {\n    _id: id\n    name\n  }\n  tagList {\n    _id: id\n    title\n    color\n  }\n  reservationsOverviewList(filters: {filters: $filters, orderBy: $orderBy}) {\n    edges {\n      cursor\n      node {\n        id\n        status\n        group\n        tags\n        number\n        name\n        roomType\n        roomCount\n        guestsCount\n        checkIn\n        checkOut\n        source\n        price\n        prepayment\n        rooming\n        orderNumber\n        country\n        optionDate\n        guestCode\n        salesCode\n        note\n        nonRefundable\n        author\n        createdAt\n        cancelledAt\n        transactionNumber\n        paymentMethod\n        attachment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d5188d32f5af55796b9144e602971c5";

export default node;
