import { GraphQLTaggedNode, IEnvironment, VariablesOf } from "relay-runtime";
import {
  ComponentType,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IconButton,
  ListActionsContainer,
  Page,
  Panel,
  SearchField,
  Suspense,
  useAppContext,
} from "h11-client-component-lib";
import {
  commitMutation,
  loadQuery,
  PreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay";
import { OperationType } from "relay-runtime/lib/util/RelayRuntimeTypes";
import { InfoCircle, Pencil, PlusCircle, Trash } from "react-bootstrap-icons";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { LoadQueryOptions } from "react-relay/relay-hooks/EntryPointTypes";

interface TableProps<TListQuery extends OperationType, TTableItem> {
  queryRef: PreloadedQuery<TListQuery>;
  onRowAction: (action: BasicRowAction, item: TTableItem) => void;
  search: string;
}

interface DialogProps {
  open: boolean;
  onClose: (submitted: boolean) => void;
}

interface DialogPropsWithQuery<TLoadInsertDialogQuery extends OperationType>
  extends DialogProps {
  queryRef: PreloadedQuery<TLoadInsertDialogQuery>;
}

interface InsertDialogWithoutQueryProps extends DialogProps {}

function paramsHaveQuery<T extends OperationType>(
  params: ParamsWithQuery<T> | ParamsWithoutQuery,
): params is ParamsWithQuery<T> {
  return !!(params as ParamsWithQuery<T>).dialogQuery;
}

interface ParamsWithQuery<TInsertDialogQuery extends OperationType> {
  dialogQuery: GraphQLTaggedNode;
  DialogComponent: ComponentType<DialogPropsWithQuery<TInsertDialogQuery>>;
}

interface ParamsWithoutQuery {
  DialogComponent: ComponentType<InsertDialogWithoutQueryProps>;
}

export interface BasicEnumPageProps<
  TTableItem,
  TListQuery extends OperationType,
  TLoadInsertDialogQuery extends OperationType,
  TLoadUpdateDialogQuery extends OperationType,
> {
  listQuery: GraphQLTaggedNode;
  updateParams?: ParamsWithQuery<TLoadUpdateDialogQuery>;
  insertParams?: (
    | ParamsWithQuery<TLoadInsertDialogQuery>
    | ParamsWithoutQuery
  ) & {
    newButtonLabel: string;
  };
  deleteParams?: {
    mutation: GraphQLTaggedNode;
    questionBuilder: (item: TTableItem) => string;
    successMessage: string;
  };
  idField: keyof TTableItem;
  label: string;
  searchPlaceholder: string;
  TableComponent: React.ComponentType<TableProps<TListQuery, TTableItem>>;
}

function DialogLoader<TLoadDialogQuery extends OperationType>({
  params,
  environment,
  variables,
  options,
  open,
  onClose,
}: {
  params: ParamsWithQuery<TLoadDialogQuery>;
  environment: IEnvironment;
  variables: VariablesOf<TLoadDialogQuery>;
  options?: LoadQueryOptions;
  open: boolean;
  onClose: (submitted: boolean) => void;
}) {
  const [dialogQueryRef, setDialogQueryRef] =
    useState<PreloadedQuery<TLoadDialogQuery>>();

  useEffect(() => {
    setDialogQueryRef(
      loadQuery(environment, params.dialogQuery, variables, options),
    );
    return () => {
      if (dialogQueryRef) {
        dialogQueryRef.dispose();
      }
    };
  }, [environment, params, variables, options]);

  return (
    dialogQueryRef && (
      <params.DialogComponent
        open={open}
        onClose={onClose}
        queryRef={dialogQueryRef}
      />
    )
  );
}

export function BasicEnumPage<
  TTableItem,
  TListQuery extends OperationType,
  TLoadInsertDialogQuery extends OperationType,
  TLoadUpdateDialogQuery extends OperationType,
>({
  listQuery,
  idField,
  insertParams,
  updateParams,
  deleteParams,
  searchPlaceholder,
  label,
  TableComponent,
}: BasicEnumPageProps<
  TTableItem,
  TListQuery,
  TLoadInsertDialogQuery,
  TLoadUpdateDialogQuery
>) {
  const [search, setSearch] = useState("");
  const [openedDialog, setOpenedDialog] = useState<"edit" | "new">();
  const [selectedItem, setSelectedItem] = useState<TTableItem>();

  const appContext = useAppContext();
  const { notify } = appContext;

  const [queryRef, loadItemsQuery] = useQueryLoader<TListQuery>(listQuery);

  const relayEnvironment = useRelayEnvironment();

  const refresh = useCallback(() => {
    loadItemsQuery({}, { fetchPolicy: "store-and-network" });
  }, [loadItemsQuery]);

  const deleteItem = useCallback(
    (item: TTableItem) => {
      if (deleteParams) {
        appContext
          .confirm(deleteParams.questionBuilder(item))
          .then(confirmed => {
            if (confirmed) {
              commitMutation(relayEnvironment, {
                variables: { id: item[idField] },
                mutation: deleteParams.mutation,
                // TODO nějaká notifikace
                onCompleted: (response, errors) => {
                  notify(deleteParams.successMessage, "success");
                  refresh();
                },
                // FIXME
                onError: error => alert("An error occurred:" + error),
              });
            }
          });
      }
    },
    [deleteParams, refresh, relayEnvironment],
  );

  useEffect(() => {
    refresh();
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      {/*FIXME me vyhodit roles-page*/}
      <Page background="grey" className="roles-page" compress>
        <Panel label={label}>
          <ListActionsContainer
            search={
              <SearchField
                style={{
                  minWidth: 400 /*TODO zobecnit, to samé je i v uživatelích*/,
                }}
                value={search}
                onChange={setSearch}
                placeholder={searchPlaceholder}
              />
            }
            actions={
              insertParams
                ? [
                    {
                      id: "create",
                      title: insertParams.newButtonLabel,
                      icon: <PlusCircle />,
                      action: () => {
                        setOpenedDialog("new");
                      },
                    },
                  ]
                : []
            }>
            <Suspense>
              {queryRef && (
                <TableComponent
                  queryRef={queryRef}
                  search={search}
                  onRowAction={(action, item) => {
                    switch (action) {
                      case "edit":
                        setOpenedDialog("edit");
                        setSelectedItem(item);
                        break;
                      case "delete":
                        deleteItem(item);
                        break;
                    }
                  }}
                />
              )}
            </Suspense>
          </ListActionsContainer>
        </Panel>
      </Page>
      {insertParams &&
        (paramsHaveQuery(insertParams) ? (
          <DialogLoader<TLoadInsertDialogQuery>
            params={insertParams}
            environment={relayEnvironment}
            variables={{}}
            options={{ fetchPolicy: "network-only" }}
            open={openedDialog === "new"}
            onClose={submitted => {
              setOpenedDialog(undefined);
              if (submitted) {
                refresh();
              }
            }}
          />
        ) : (
          <insertParams.DialogComponent
            open={openedDialog === "new"}
            onClose={submitted => {
              setOpenedDialog(undefined);
              if (submitted) {
                refresh();
              }
            }}
          />
        ))}
      {updateParams && selectedItem && (
        <DialogLoader
          params={updateParams}
          environment={relayEnvironment}
          variables={{ id: selectedItem[idField] }}
          options={{ fetchPolicy: "network-only" }}
          open={openedDialog === "edit"}
          onClose={submitted => {
            setOpenedDialog(undefined);
            if (submitted) {
              refresh();
            }
          }}
        />
      )}
    </>
  );
}

export function useButtonColumn<TData>({
  id,
  title,
  onAction,
  icon,
}: {
  id: string;
  title: string;
  onAction: (item: TData) => void;
  icon: ReactElement;
}) {
  return useMemo(
    () =>
      ({
        id: id,
        enableHiding: false,
        header: () => <div className="center">{title}</div>,
        enableSorting: false,
        cell: c => (
          <div className="center">
            <IconButton onClick={() => onAction(c.row.original)}>
              {icon}
            </IconButton>
          </div>
        ),
      }) as ColumnDef<TData>,
    [onAction],
  );
}

export type BasicRowAction = "info" | "edit" | "delete";

export function useInfoColumn<TData>(onAction: (item: TData) => void) {
  const { t } = useTranslation();
  return useButtonColumn({
    id: "info",
    title: t("info"),
    onAction,
    icon: <InfoCircle />,
  });
}

export function useEditColumn<TData>(onAction: (item: TData) => void) {
  const { t } = useTranslation();
  return useButtonColumn({
    id: "edit",
    title: t("edit"),
    onAction,
    icon: <Pencil />,
  });
}

export function useDeleteColumn<TData>(onAction: (item: TData) => void) {
  const { t } = useTranslation();
  return useButtonColumn({
    id: "delete",
    title: t("delete"),
    onAction,
    icon: <Trash />,
  });
}

export function useBasicButtonsColumns<TData>(
  onRowAction: (action: BasicRowAction, item: TData) => void,
): ColumnDef<TData>[] {
  const infoCallback = useCallback(
    (item: TData) => onRowAction("info", item),
    [onRowAction],
  );
  const editCallback = useCallback(
    (item: TData) => onRowAction("edit", item),
    [onRowAction],
  );
  const deleteCallback = useCallback(
    (item: TData) => onRowAction("delete", item),
    [onRowAction],
  );

  const infoColumn = useInfoColumn<TData>(infoCallback);
  const editColumn = useEditColumn<TData>(editCallback);
  const deleteColumn = useDeleteColumn<TData>(deleteCallback);

  return useMemo(
    () => [infoColumn, editColumn, deleteColumn],
    [infoColumn, editColumn, deleteColumn],
  );
}
