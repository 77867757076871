import { BFF_URI } from "@params";
import { useOAuthLoginRedirect } from "@store";
import { useAppContext } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";

export const useLogout = () => {
  const oAuthLoginRedirect = useOAuthLoginRedirect();
  const context = useAppContext();
  const { t } = useTranslation();
  return async () => {
    const logoutRes = await fetch(`${BFF_URI}/logout`, {
      method: "POST",
      credentials: "same-origin",
    });

    if (!logoutRes.ok) {
      context.notify(t("error_cannot_logout"), "danger");
    } else {
      oAuthLoginRedirect();
    }
  };
};
