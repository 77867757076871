import {
  ListActionsContainer,
  Page,
  Panel,
  SearchField,
  Suspense,
  useAppContext,
} from "h11-client-component-lib";
import { useEffect, useState } from "react";
import { useMutation, useQueryLoader } from "react-relay";
import { PlusCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { UserTableItem } from "./userData";
import { UserUpdateDialog } from "./dialogs/UserUpdateDialog";
import { RobotUserInsertDialog } from "./dialogs/RobotUserInsertDialog";
import { UserInsertDialog } from "./dialogs/UserInsertDialog";
import { usersQuery } from "./graphql/UsersQuery";
import { UsersQuery } from "@relay-generated/UsersQuery.graphql";
import { userQuery } from "./graphql/UserQuery";
import { userActiveMutation } from "./graphql/UserActiveMutation";
import { userDeleteMutation } from "./graphql/UserDeleteMutation";
import { UserActiveMutation } from "@relay-generated/UserActiveMutation.graphql";
import { UserDeleteMutation } from "@relay-generated/UserDeleteMutation.graphql";
import { UserQuery } from "@relay-generated/UserQuery.graphql";
import { UsersTable } from "./UsersTable";

export function UsersPage() {
  const { t } = useTranslation();
  const [showInactive, setShowInactive] = useState(false);
  const [search, setSearch] = useState("");
  const [openedDialog, setOpenedDialog] = useState<
    "edit" | "new" | "new_robot"
  >();

  const appContext = useAppContext();

  const [queryRef, loadQuery] = useQueryLoader<UsersQuery>(usersQuery);

  const { notify } = useAppContext();

  const [updateDialogQueryRef, loadUpdateDialogQuery] =
    useQueryLoader<UserQuery>(userQuery);

  const [userActiveMutationCommit] =
    useMutation<UserActiveMutation>(userActiveMutation);

  const [userDeleteMutationCommit] =
    useMutation<UserDeleteMutation>(userDeleteMutation);

  function refresh() {
    loadQuery(
      { active: showInactive ? null : true },
      { fetchPolicy: "store-and-network" },
    );
  }

  useEffect(() => {
    refresh();
  }, [showInactive]);

  function deleteUser({ firstName, lastName, userUid }: UserTableItem) {
    appContext
      .confirm(
        t("delete_user_question", { userName: `${firstName} ${lastName}` }),
      )
      .then(confirmed => {
        if (confirmed) {
          userDeleteMutationCommit({
            variables: { userUid },
            // TODO nějaká notifikace
            onCompleted: (response, errors) => {
              notify(t("user_deleted"), "success");
              refresh();
            },
            // FIXME
            onError: error => alert("An error occurred:" + error),
          });
        }
      });
  }

  function changeUserActive(
    { firstName, lastName, userUid }: UserTableItem,
    active: boolean,
  ) {
    appContext
      .confirm(
        t("deactivate_user_question", { userName: `${firstName} ${lastName}` }),
        active,
      )
      .then(confirmed => {
        if (confirmed) {
          userActiveMutationCommit({
            variables: { userUid, active },
            // TODO nějaká notifikace
            onCompleted: (response, errors) => {
              notify(
                t(active ? "user_activated" : "user_deactivated"),
                "success",
              );
              refresh();
            },
            // FIXME
            onError: error => alert("An error occurred:" + error),
          });
        }
      });
  }

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Page background="grey" compress>
        <Panel label={t("users_list")}>
          <ListActionsContainer
            search={
              <SearchField
                style={{
                  minWidth: 400 /*TODO zobecnit, to samé je i v rolích*/,
                }}
                value={search}
                onChange={setSearch}
                placeholder={t("user_search_placeholder")}
              />
            }
            inactive={{
              id: "show-inactive-users",
              show: showInactive,
              onChange: setShowInactive,
            }}
            actions={[
              {
                id: "create_new_user",
                title: t("create_new_user"),
                icon: <PlusCircle />,
                action: () => {
                  setOpenedDialog("new");
                },
              },
              {
                id: "create_new_technical_user",
                title: t("create_new_technical_user"),
                icon: <PlusCircle />,
                action: () => {
                  setOpenedDialog("new_robot");
                },
              },
            ]}>
            <Suspense>
              {queryRef && (
                <UsersTable
                  search={search}
                  queryRef={queryRef}
                  onRowAction={(action, user) => {
                    switch (action) {
                      case "edit":
                        setOpenedDialog("edit");
                        loadUpdateDialogQuery(
                          { id: user.userUid },
                          { fetchPolicy: "network-only" },
                        );
                        break;
                      case "activate":
                      case "deactivate":
                        changeUserActive(user, action === "activate");
                        break;
                      case "delete":
                        deleteUser(user);
                        break;
                    }
                  }}
                />
              )}
            </Suspense>
          </ListActionsContainer>
        </Panel>
      </Page>
      {/*TODO suspense asi jinam, aby zobrazil loading už v dialogu?*/}
      {/*TODO nemohli / neměli by být dialogy nějak zkombinované? Alespoň insert/update?*/}
      <UserInsertDialog
        open={openedDialog === "new"}
        onClose={submitted => {
          setOpenedDialog(undefined);
          if (submitted) {
            refresh();
          }
        }}
      />
      <RobotUserInsertDialog
        open={openedDialog === "new_robot"}
        onClose={submitted => {
          setOpenedDialog(undefined);
          if (submitted) {
            refresh();
          }
        }}
      />
      {updateDialogQueryRef && (
        <UserUpdateDialog
          queryRef={updateDialogQueryRef}
          open={openedDialog === "edit"}
          onClose={submitted => {
            setOpenedDialog(undefined);
            if (submitted) {
              refresh();
            }
          }}
        />
      )}
    </>
  );
}
