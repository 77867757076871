import { useFragment } from "react-relay";
import { userRightsResourceFragment } from "@shared/graphql/UserRightsResource";
import { useMemo } from "react";
import { ResourceRightsDetail } from "@shared/data/ResourceRightsData";
import {
  SystemRoles,
  UserRightsResource_resource$key,
} from "@relay-generated/UserRightsResource_resource.graphql";
import { AssignedRoleDetail } from "../../../pages/users/userData";

export function useMergedResourcesDefinition(data: {
  usersResourcesDefinition: UserRightsResource_resource$key;
  hotelsResourcesDefinition: UserRightsResource_resource$key;
  reservationResourcesDefinition: UserRightsResource_resource$key;
  fileStoreResourcesDefinition: UserRightsResource_resource$key;
}) {
  const usersResourcesDefinition = useFragment(
    userRightsResourceFragment,
    data.usersResourcesDefinition,
  );
  const hotelsResourcesDefinition = useFragment(
    userRightsResourceFragment,
    data.hotelsResourcesDefinition,
  );
  const reservationResourcesDefinition = useFragment(
    userRightsResourceFragment,
    data.reservationResourcesDefinition,
  );
  const fileStoreResourcesDefinition = useFragment(
    userRightsResourceFragment,
    data.fileStoreResourcesDefinition,
  );
  return useMemo(
    () => [
      ...usersResourcesDefinition,
      ...hotelsResourcesDefinition,
      ...reservationResourcesDefinition,
      ...fileStoreResourcesDefinition,
    ],
    [data],
  );
}

/**
 * Returns an array of resource rights based on the provided resource definitions, roles, and additional system role.
 * Used for extracting all enabled resource rights from definitions based on roles (and their system roles) and additional system role.
 *
 * @param {readonly ResourceRightsDetail[]} resourcesDefinitions - The array of resource definitions.
 * @param {readonly AssignedRoleDetail[]} [roles] - The array of assigned roles.
 * @param {SystemRoles} [additionalSystemRole] - The additional system role.
 * @returns {Array} - An array of resource rights containing action, namespace, resource, and source information.
 */
export function useFixedResourceRights(
  resourcesDefinitions: readonly ResourceRightsDetail[],
  roles?: readonly AssignedRoleDetail[],
  additionalSystemRole?: SystemRoles,
) {
  return useMemo(() => {
    const rightsByRoles =
      roles?.flatMap(r =>
        r.customResourceRights.map(c => ({
          ...c,
          source: r.name,
        })),
      ) ?? [];

    const selectedSystemRoles =
      roles
        ?.filter(r => r.systemRole)
        .map(r => ({
          systemRole: r.systemRole!,
          source: r.name as string | undefined,
        })) ?? [];

    if (additionalSystemRole) {
      selectedSystemRoles.push({
        systemRole: additionalSystemRole,
        source: undefined,
      });
    }

    const rightsBySystemRoles = resourcesDefinitions.flatMap(d =>
      d.actions
        .map(a => {
          const firstFoundSystemRole = selectedSystemRoles.find(s =>
            a.systemRoles.includes(s.systemRole),
          );

          return firstFoundSystemRole
            ? {
                action: a.action,
                namespace: d.namespace,
                resource: d.resource,
                source: firstFoundSystemRole.source,
              }
            : undefined;
        })
        .filter(a => !!a),
    );

    return [...rightsByRoles, ...rightsBySystemRoles];
  }, [roles, additionalSystemRole, resourcesDefinitions]);
}
