import { graphql } from "react-relay";

export const localSessionQuery = graphql`
  query LocalSessionQuery {
    userCurrentGet {
      ...CurrentUserFragment
    }

    hotelChainHotelsList {
      code
      hotelChainUid
      hotelUid
      hotelName
    }
  }
`;
