import { useFormikContext } from "formik";
import { UserFormData } from "../userData";
import {
  Button,
  FlowLayout,
  FormGrid,
  FormikDateField,
  FormikSelectField,
  FormikTextField,
  languages,
  Switch,
  useAppContext,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation } from "react-relay";
import { RobotUserResetTokenMutation } from "@relay-generated/RobotUserResetTokenMutation.graphql";
import { RobotUserTokenCopyPromptDialog } from "../dialogs/RobotUserTokenCopyPromptDialog";
import { robotUserResetTokenMutation } from "./graphql/RobotUserResetTokenMutation";

export function RobotUserAccountForm() {
  const { t } = useTranslation();
  const appContext = useAppContext();
  const formik = useFormikContext<UserFormData>();
  const isNew = !formik.values.userUid;

  const [newToken, setNewToken] = useState<string>();

  const [resetTokenCommit] = useMutation<RobotUserResetTokenMutation>(
    robotUserResetTokenMutation,
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 50 }}>
      <FormGrid gridTemplateColumns="repeat(4, minmax(100px, 1fr))">
        <FormikTextField label={t("user_name")} field="userName" />
        <FormikDateField label={t("valid_to")} field="validTo" />
        <Switch
          id="chain-user"
          style={{ alignSelf: "flex-start", marginTop: 32 }}
          label={t("chain_user")}
          checked={formik.values["chainUser"] ?? false}
          onChange={checked => {
            const doIt = () => formik.setFieldValue("chainUser", checked);
            if (checked) {
              doIt();
            } else {
              appContext
                .confirm(
                  t("turn_off_chain_user_question", { hotelName: "TODO" }),
                )
                .then(confirmed => {
                  if (confirmed) {
                    doIt();
                  }
                });
            }
          }}
        />
        <FormikTextField
          label={t("first_name")}
          field="firstName"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField label={t("last_name")} field="lastName" />
        <FormikTextField label={t("code")} field="code" />
        <FormikSelectField
          label={t("language")}
          field="lang"
          items={languages}
          itemIdExtractor={l => l}
          valueToString={l => l}
        />
        <FlowLayout
          variant="compact"
          style={{ gridColumn: "1", gridColumnEnd: "4" }}>
          {isNew ? (
            <div>{t("user_token_will_show_after_save")}</div>
          ) : (
            <Button
              variant="secondary"
              style={{ alignSelf: "center", marginTop: 2 }}
              onClick={() => {
                appContext
                  .confirm(t("generate_new_token_warning"))
                  .then(res => {
                    if (res && formik.values.userUid) {
                      resetTokenCommit({
                        variables: {
                          id: formik.values.userUid,
                        },
                        onCompleted: ({ robotUserResetToken: { token } }) => {
                          appContext.notify(t("token_reset"), "success");
                          setNewToken(token);
                        },
                      });
                    }
                  });
              }}>
              {t("generate_new_token")}
            </Button>
          )}
        </FlowLayout>
        <FormikTextField
          label={t("note")}
          field="note"
          multiline={3}
          style={{ gridColumn: "1 / 3" }}
        />
      </FormGrid>
      {newToken && (
        <RobotUserTokenCopyPromptDialog
          token={newToken}
          onClose={() => {
            setNewToken(undefined);
          }}
        />
      )}
    </div>
  );
}
