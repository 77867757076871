import { BFF_URI } from "../params";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  Variables,
} from "relay-runtime";
import { RequestParameters } from "relay-runtime/lib/util/RelayConcreteNode";

export const loginNeededHttpStatuses = [401, 403];

export class LoginNeededError extends Error {}

const createFetchFunction =
  (hotelId: string | null, langGetter: () => string) =>
  async (operation: RequestParameters, variables: Variables) => {
    if (!hotelId) {
      return Promise.reject();
    }

    const data = await fetch(`${BFF_URI}/api`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Hores-Scope": hotelId,
        "Accept-Language": langGetter(),
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    });

    console.debug(
      JSON.stringify({
        query: operation.text,
        variables,
      }),
    );

    // TODO https://react.dev/learn/react-developer-tools

    if (data.ok) {
      return await data.json();
    } else if (loginNeededHttpStatuses.includes(data.status)) {
      throw new LoginNeededError();
    } else {
      throw Error("Invalid response: " + data.status);
    }
  };

export const createRelayEnvironment = (
  hotelId: string | null,
  langGetter: () => string,
) =>
  new Environment({
    network: Network.create(createFetchFunction(hotelId, langGetter)),
    store: new Store(new RecordSource()),
  });
