import { graphql } from "react-relay";

export const discountsQuery = graphql`
  query DiscountsQuery {
    discountList {
      _id: id
      name
      value
    }
  }
`;
