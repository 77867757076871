import { graphql } from "react-relay";

export const userRightsFormDataQuery = graphql`
  query UserRightsFormDataQuery {
    roleList {
      _id: id
      name
      customResourceRights {
        namespace
        resource
        action
      }
      applications {
        _id: id
      }
      systemRole
      description
    }

    hotelsResourcesDefinition {
      ...UserRightsResource_resource
    }
    fileStoreResourcesDefinition {
      ...UserRightsResource_resource
    }
    usersResourcesDefinition {
      ...UserRightsResource_resource
    }
    reservationResourcesDefinition {
      ...UserRightsResource_resource
    }
  }
`;
