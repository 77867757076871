import { useTranslation } from "react-i18next";
import { BasicEnumPage } from "../BasicEnumPage";
import { reservationSourcesQuery } from "./graphql/ReservationSourcesQuery";
import { ReservationSourcesTable } from "./ReservationSourcesTable";
import { reservationSourceUpdateDialogQuery } from "./graphql/ReservationSourceUpdateDialogQuery";
import { ReservationSourceUpdateDialog } from "./dialogs/ReservationSourceUpdateDialog";
import { reservationSourceInsertDialogQuery } from "./graphql/ReservationSourceInsertDialogQuery";
import { ReservationSourceInsertDialog } from "./dialogs/ReservationSourceInsertDialog";

export function ReservationSourcesPage() {
  const { t } = useTranslation();

  return (
    <BasicEnumPage
      listQuery={reservationSourcesQuery}
      label={t("reservation_sources_list")}
      searchPlaceholder={t("reservation_source_search_placeholder")}
      TableComponent={ReservationSourcesTable}
      updateParams={{
        dialogQuery: reservationSourceUpdateDialogQuery,
        DialogComponent: ReservationSourceUpdateDialog,
      }}
      insertParams={{
        dialogQuery: reservationSourceInsertDialogQuery,
        DialogComponent: ReservationSourceInsertDialog,
        newButtonLabel: t("create_new_reservation_source"),
      }}
      idField="_id"
    />
  );
}
