import { useTranslation } from "react-i18next";
import { AlertIcon } from "h11-client-component-lib";
import "@shared/ui/copyPrompt/CopyPromptDialog.scss";
import { CopyPromptDialog } from "@shared/ui/copyPrompt/CopyPromptDialog";

export const RobotUserTokenCopyPromptDialog = ({
  token,
  onClose,
}: {
  token: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <CopyPromptDialog
      open={!!token}
      title={t("token_generated")}
      fieldLabel={t("token")}
      text={token}
      message={
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <AlertIcon />
          <span>{t("token_must_be_copied_now")}</span>
        </div>
      }
      onClose={onClose}
    />
  );
};
