import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { reservationSourceBaseSchema } from "../reservationSourceData";
import { reservationSourceInsertDialogQuery } from "../graphql/ReservationSourceInsertDialogQuery";
import { ReservationSourceInsertDialogQuery } from "@relay-generated/ReservationSourceInsertDialogQuery.graphql";
import { ReservationSourceBaseDialog } from "./ReservationSourceBaseDialog";

export function ReservationSourceInsertDialog({
  queryRef,
  ...restProps
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  queryRef: PreloadedQuery<ReservationSourceInsertDialogQuery>;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(reservationSourceInsertDialogQuery, queryRef);

  return (
    <ReservationSourceBaseDialog
      {...restProps}
      title={t("reservation_source_insert")}
      data={data}
      initialValues={{}}
      schema={reservationSourceBaseSchema}
    />
  );
}
