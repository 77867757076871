import { OwnProfileFormData } from "../../userData";
import {
  Button,
  FormGrid,
  FormikPhotoDropZone,
  FormikSelectField,
  FormikTextField,
  languages,
  SelectField,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NotificationsGroupsPanel } from "../../forms/NotificationsGroupsPanel";
import { Key } from "react-bootstrap-icons";
import { OwnPasswordChangeDialog } from "../OwnPasswordChangeDialog";

export function OwnProfileForm() {
  const { t } = useTranslation();
  const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] =
    useState(false);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 50 }}>
      <FormGrid gridTemplateColumns="repeat(4, minmax(100px, 1fr))">
        <FormikPhotoDropZone<OwnProfileFormData> field="avatarFile" />
        <FormikTextField
          label={t("first_name")}
          field="firstName"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField label={t("last_name")} field="lastName" />
        <FormikTextField label={t("code")} field="code" />
        {/*Extract Formik language select component, which is used on more places*/}
        <FormikSelectField
          label={t("language")}
          field="lang"
          items={languages}
          itemIdExtractor={l => l}
          valueToString={l => l}
        />
        <FormikTextField
          style={{ gridColumn: 1 }}
          label={t("hotel_phone")}
          field="hotelTel"
        />
        <FormikTextField
          label={t("hotel_email")}
          field="hotelEmail"
          style={{ gridColumn: "2 / 4" }}
        />
        <FormikTextField
          label={t("insert_signature")}
          field="hotelSignature"
          multiline={3}
          placeholder={t("insert_signature_placeholder")}
          style={{ gridColumn: "1 / 3" }}
        />
        <NotificationsGroupsPanel style={{ gridColumn: "1" }} />
        <SelectField
          label={t("theme")}
          value="light"
          onChange={() => {}}
          items={["dark", "light"]}
          valueToString={r => r}
          itemIdExtractor={r => r}
        />
        <Button
          variant="secondary"
          icon={<Key />}
          onClick={() => setPasswordChangeDialogOpen(true)}
          style={{
            alignSelf: "flex-start",
            justifySelf: "flex-start",
            marginTop: 24,
          }}>
          {t("change_password")}
        </Button>
      </FormGrid>
      <OwnPasswordChangeDialog
        open={passwordChangeDialogOpen}
        onClose={() => setPasswordChangeDialogOpen(false)}
      />
    </div>
  );
}
