import { Table } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useDeleteColumn, useEditColumn } from "../../BasicEnumPage";

interface TableItem {
  name: string;
}

export function InvoiceAddressesTable() {
  const { t } = useTranslation();

  const editCallback = useCallback((item: TableItem) => alert("TODO"), []);
  const deleteCallback = useCallback((item: TableItem) => alert("TODO"), []);

  const editColumn = useEditColumn<TableItem>(editCallback);
  const deleteColumn = useDeleteColumn<TableItem>(deleteCallback);

  return (
    <Table
      customizableColumns={false}
      columns={[
        {
          accessorKey: "name",
          header: t("title"),
        },
        editColumn,
        deleteColumn,
      ]}
      data={[
        {
          name: "Booking.com",
        },
      ]}
      fullWidth
    />
  );
}
