/**
 * @generated SignedSource<<d4c572e14645c12fab0fa4ed47c32dcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RobotUserResetTokenMutation$variables = {
  id: string;
};
export type RobotUserResetTokenMutation$data = {
  readonly robotUserResetToken: {
    readonly token: string;
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
  };
};
export type RobotUserResetTokenMutation = {
  response: RobotUserResetTokenMutation$data;
  variables: RobotUserResetTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "userUid",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "tokenReset"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "PayloadMessages_message",
    "selections": (v3/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RobotUserResetTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TokenResetPayload",
        "kind": "LinkedField",
        "name": "robotUserResetToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "PayloadMessages_interface",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "warnings",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "PayloadInterface",
                "abstractKey": "__isPayloadInterface"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RobotUserResetTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TokenResetPayload",
        "kind": "LinkedField",
        "name": "robotUserResetToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "type": "PayloadInterface",
            "abstractKey": "__isPayloadInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bd11fffdc9f4e120c90c0379500d39f",
    "id": null,
    "metadata": {},
    "name": "RobotUserResetTokenMutation",
    "operationKind": "mutation",
    "text": "mutation RobotUserResetTokenMutation(\n  $id: UUID!\n) {\n  robotUserResetToken(tokenReset: {userUid: $id}) {\n    token\n    ...PayloadMessages_interface\n  }\n}\n\nfragment PayloadMessages_interface on PayloadInterface {\n  __isPayloadInterface: __typename\n  errors {\n    ...PayloadMessages_message\n  }\n  warnings {\n    ...PayloadMessages_message\n  }\n}\n\nfragment PayloadMessages_message on PayloadMessage {\n  code\n  text\n}\n"
  }
};
})();

(node as any).hash = "a54bf2b621049fbf7af87769e4301c1e";

export default node;
