import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { useEffect } from "react";
import { FormDialog } from "h11-client-component-lib";
import {
  reservationSourceBaseSchema,
  ReservationSourceFormData,
} from "../reservationSourceData";
import { ReservationSourceForm } from "../ReservationSourceForm";
import { ReservationSourceUpdateDialogQuery } from "@relay-generated/ReservationSourceUpdateDialogQuery.graphql";
import { ReservationSourceInsertDialogQuery } from "@relay-generated/ReservationSourceInsertDialogQuery.graphql";
import { useApiForm } from "@shared/forms/apiForm";

export function ReservationSourceBaseDialog<
  TQuery extends
    | ReservationSourceUpdateDialogQuery
    | ReservationSourceInsertDialogQuery,
  TSchema extends typeof reservationSourceBaseSchema,
>({
  open,
  onClose,
  schema,
  initialValues,
  title,
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  data: TQuery["response"];
  schema: TSchema;
  initialValues: ReservationSourceFormData;
  title: string;
}) {
  const { t } = useTranslation();

  const { formik } = useApiForm<
    ReservationSourceFormData,
    TSchema,
    { dummy: null }
  >({
    initialValues,
    schema,
    messages: {
      success: t("reservation_source_saved"),
    },
    onSubmit: (values, relayEnvironment) => {
      return Promise.resolve({ responses: { dummy: null } });
      /*return prepareApiCall<TMutation, never>({
        mutation: mutation,
        variables: mutationVariablesExtractor(values),
        environment: relayEnvironment,
      }).execute();*/
    },
    visible: open,
    onSuccess: () => {
      onClose(true);
    },
  });

  console.log(formik.errors);

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues(initialValues, true);
    }
  }, [open]);

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose(false)}
        title={title}
        variant="wide">
        <ReservationSourceForm />
      </FormDialog>
    </FormikProvider>
  );
}
