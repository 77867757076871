/**
 * @generated SignedSource<<99b656115e660d825a23643681b05cff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentUserFragment$data = {
  readonly avatar: {
    readonly url: string;
  } | null | undefined;
  readonly chainUser: boolean;
  readonly code: string;
  readonly firstName: string | null | undefined;
  readonly hotelEmail: string | null | undefined;
  readonly hotelSignature: string | null | undefined;
  readonly hotelTel: string | null | undefined;
  readonly lang: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly userName: string;
  readonly userUid: string;
  readonly " $fragmentType": "CurrentUserFragment";
};
export type CurrentUserFragment$key = {
  readonly " $data"?: CurrentUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentUserFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "CurrentUserFragment"
};

(node as any).hash = "bb37ab110262d9826449773b835a0ea1";

export default node;
