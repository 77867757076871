import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { BFF_URI, HOTELLESS_DOMAIN, OAUTH_REDIRECT_URL } from "@params";
import { Loading } from "h11-client-component-lib";

interface DomainHotelInfoResponse {
  oAuthLoginUrl: string;
  hotelUid: string;
  hotelCode: string;
  hotelName: string;
}

const DomainHotelInfoContext = createContext<
  DomainHotelInfoResponse | undefined
>(undefined);

export function DomainHotelInfoContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [domainHotelInfoContextValues, setDomainHotelInfoContextValues] =
    useState<DomainHotelInfoResponse | Error>();

  useEffect(() => {}, []);

  useEffect(() => {
    const { host } = window.location;

    const match = host.match(new RegExp(`(.+)\\.${HOTELLESS_DOMAIN}`));

    const hotelCode = match?.[1];
    if (hotelCode) {
      const func = async () => {
        setDomainHotelInfoContextValues(undefined);

        const hotelInfoParams = new URLSearchParams();
        hotelInfoParams.set("hotelCode", hotelCode);
        hotelInfoParams.set("url", window.location.href);
        hotelInfoParams.set("oAuthRedirectUrl", OAUTH_REDIRECT_URL);

        const domainHotelInfo = await fetch(
          `${BFF_URI}/domainHotelInfo?${hotelInfoParams}`,
          {
            credentials: "same-origin",
          },
        ).then(async res => {
          if (res.ok) {
            return (await res.json()) as DomainHotelInfoResponse;
          } else {
            return null;
          }
        });

        if (domainHotelInfo) {
          setDomainHotelInfoContextValues(domainHotelInfo);
        } else {
          setDomainHotelInfoContextValues(new UnknownHotelError());
        }
      };

      // noinspection JSIgnoredPromiseFromCall
      func();
    } else {
      throw new UnspecifiedHotelError();
    }

    // Intentionally having only host here, so it's not rerun on window.location.href change.
    // So the initialUrl is set only when host changes (practically i.e., first load)
  }, [window.location.host]);

  if (domainHotelInfoContextValues instanceof Error) {
    throw domainHotelInfoContextValues;
  }

  return domainHotelInfoContextValues ? (
    <DomainHotelInfoContext.Provider value={domainHotelInfoContextValues}>
      {children}
    </DomainHotelInfoContext.Provider>
  ) : (
    // TODO spíš supsense? Nebo jinak sjednotit všechny ty loadingy před zobrazením aplikace?
    <Loading size={300} centerVertical />
  );
}

export const useDomainHotelInfo = () => {
  const context = useContext(DomainHotelInfoContext);
  if (!context) {
    throw new Error(
      "useDomainHotelInfo must be used within a DomainHotelInfoContextProvider",
    );
  }

  return context;
};

export class UnspecifiedHotelError extends Error {}

export class UnknownHotelError extends Error {}
