import { Avatar, AvatarProps } from "h11-client-component-lib";

type UserAvatarProps = Omit<AvatarProps, "src" | "text"> & {
  user: {
    userUid: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      url?: string | null;
    } | null;
  };
};

export function UserAvatar({
  user: { avatar, firstName, lastName, userUid },
  ...restProps
}: UserAvatarProps) {
  const text = firstName && lastName ? firstName[0] + lastName[0] : undefined;
  console.log(firstName + " " + lastName + " = " + userUid);
  return (
    <Avatar
      src={avatar?.url ?? undefined}
      text={text}
      colorHashString={userUid}
      {...restProps}
    />
  );
}
