import { graphql } from "react-relay";

// FIXME DUMMY
export const reservationSourceUpdateDialogQuery = graphql`
  query ReservationSourceUpdateDialogQuery($id: Int!) {
    roleGet(roleId: $id) {
      _id: id
      name
    }
  }
`;
