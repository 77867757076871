import {
  ActiveColumnFilter,
  Button,
  DateField,
  DateRangeField,
  FlowGroup,
  FlowLayout,
  FormItem,
  FullDateRange,
  IconButton,
  Page,
  RowSelection,
  SearchField,
  Suspense,
  Tag,
  Tags,
  ToggleButtonGroup,
  ToggleItem,
} from "h11-client-component-lib";
import { t } from "i18next";
import { createContext, useEffect, useState } from "react";
import { ArrowCounterclockwise, PlusCircle } from "react-bootstrap-icons";
import { useQueryLoader } from "react-relay";
import { Dayjs } from "dayjs";
import { ReservationsTable } from "./ReservationsTable";
import {
  HighlightedReservationTableItem,
  StateFilterType,
} from "./reservationsData";
import { reservationsQuery } from "./graphql/ReservationsQuery";
import { ReservationsQuery } from "@relay-generated/ReservationsQuery.graphql";
import "./index.scss";

export const ReservationsContext = createContext<{
  columnFilters: ActiveColumnFilter[];
  setColumnFilters: (filters: ActiveColumnFilter[]) => void;
  selection?: RowSelection<HighlightedReservationTableItem>;
  setSelection: (item?: RowSelection<HighlightedReservationTableItem>) => void;
} | null>(null);

export function ReservationsPage() {
  const [search, setSearch] = useState("");
  const [mainFilter, setMainFilter] = useState<StateFilterType>("all");
  const [stayDateFilter, setStayDateFilter] = useState<Dayjs>();
  const [queryRef, loadQuery] =
    useQueryLoader<ReservationsQuery>(reservationsQuery);

  // FIXME Pokud načtu stránku přes F5, první jazyk je angličtina a ne čeština

  const [columnFilters, setColumnFilters] = useState<ActiveColumnFilter[]>([]);
  const [selection, setSelection] =
    useState<RowSelection<HighlightedReservationTableItem>>();

  useEffect(() => {
    loadQuery(
      {
        filters: {
          ...Object.fromEntries(
            columnFilters
              .filter(
                f =>
                  f.id ===
                  "autor" /*FIXME also others - make it general, name of the field in $filters and value extractor -> ie. to extract ID from selected sources*/,
              )
              .map(f => [
                f.id,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (f.value as any[] | undefined)?.map(o => o.userUid),
              ]),
          ),
          ...Object.fromEntries(
            columnFilters
              .filter(f => f.id === "tags")
              .map(f => [
                f.id,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (f.value as any[] | undefined)?.map(o => o.id),
              ]),
          ),
          search,
        },
      },
      { fetchPolicy: "store-and-network" },
    );
  }, [columnFilters, search]);

  return (
    <ReservationsContext.Provider
      value={{
        columnFilters,
        setColumnFilters,
        selection,
        setSelection,
      }}>
      <Page variant="compact">
        <FlowLayout>
          <div className="page-title Font--section-heading">
            {t("reservations_overview")}
          </div>
          <FlowGroup>
            <div className="Font--regular-acc">{t("active_filter")}:</div>
            {columnFilters.length > 0 ? (
              <>
                <Tags>
                  {columnFilters.map(c => (
                    <Tag
                      key={c.id}
                      onDelete={() => {
                        setColumnFilters(f => f.filter(i => i.id !== c.id));
                      }}>
                      {c.label}
                    </Tag>
                  ))}
                </Tags>
                <IconButton onClick={() => setColumnFilters([])}>
                  <ArrowCounterclockwise />
                </IconButton>
              </>
            ) : (
              <span
                style={{
                  padding: 4,
                  fontSize: 12 /*FIXME stejná velikost jako tagy, aby layout neskákal, když se mění filtry*/,
                }}>
                {t("none")}
              </span>
            )}
          </FlowGroup>
          <FlowGroup>
            <div className="Font--regular">
              {t("active_filters_result_row_count")}:
            </div>
            <div className="Font--regular-big">
              123456 {t("reservations_count")}
            </div>
          </FlowGroup>
        </FlowLayout>
        <FlowLayout style={{ marginBottom: 20 }}>
          <SearchField
            value={search}
            onChange={setSearch}
            label={t("search")}
            helperText="Zadejte číslo, název, objednávku, nebo číslo transakce rezervace."
          />
          <ToggleButtonGroup
            label="&nbsp;"
            name="TODO"
            value={mainFilter}
            onChange={setMainFilter}>
            <ToggleItem value="all">{t("all")}</ToggleItem>
            <ToggleItem value="accommodated">{t("accommodated")}</ToggleItem>
            <ToggleItem value="valid">{t("valid")}</ToggleItem>
            <ToggleItem value="cancelled">{t("cancelled")}</ToggleItem>
          </ToggleButtonGroup>

          <DateField
            label={t("stay")}
            style={{ minWidth: 160 }}
            value={stayDateFilter}
            onChange={setStayDateFilter}
          />

          <DateRangeField
            label={t("created")}
            showPredefinedDates
            multiMonth
            value={
              columnFilters.find(i => i.id === "createdAt")?.value as
                | FullDateRange
                | undefined
            }
            onChange={v => {
              setColumnFilters(f => {
                const newFilters = f.filter(i => i.id !== "createdAt");
                if (v) {
                  newFilters.push({
                    id: "createdAt",
                    label: t("created"),
                    value: v,
                  });
                }
                return newFilters;
              });
            }}
          />

          <FormItem label="&nbsp;">
            <Button onClick={() => alert("TODO")} icon={<PlusCircle />}>
              {t("new_reservation")}
            </Button>
          </FormItem>
        </FlowLayout>

        <Suspense>
          {queryRef && (
            <ReservationsTable queryRef={queryRef} search={search} />
          )}
        </Suspense>
      </Page>
    </ReservationsContext.Provider>
  );
}
