import { useTranslation } from "react-i18next";
import { HighlightedReservationTableItem } from "./reservationsData";
import { Check2, Pencil, PersonCheck, X, XLg } from "react-bootstrap-icons";
import { ColumnDef } from "@tanstack/react-table";
import { SelectFilterDef, Tooltipped } from "h11-client-component-lib";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { ReservationsQuery$data } from "@relay-generated/ReservationsQuery.graphql";
import { UserAvatar } from "@shared/ui/UserAvatar";
import dayjs from "dayjs";

interface DummySelectItem {
  id: number | string;
  label: string;
}

interface ReservationState {
  id: string;
  label: string;
  icon: ReactElement;
}

function CheckIcon({ green }: { green: boolean }) {
  // TODO možná zobecnit i s tím, co je v UsersTable, tlačítky a podobně?
  return (
    <div className="center">
      <Check2
        style={
          green ? { color: "rgba(0, 172, 17, 1)" /*Barva z SCSS*/ } : undefined
        }
      />
    </div>
  );
}

export function useReservationsColumns(
  data: ReservationsQuery$data,
): ColumnDef<HighlightedReservationTableItem>[] {
  const { t } = useTranslation();

  // TODO asi dpo redux?, pravděpodobně se bude používat i jinde
  const states = useMemo<Map<string, ReservationState>>(
    () =>
      new Map(
        [
          {
            id: "CANCELLED",
            label: t("cancelled"),
            icon: <XLg className="reservation-state cancelled" />,
          },
          {
            id: "TENTATIVE",
            label: t("tentative"),
            icon: <div className="reservation-state tentative" />,
          },
          {
            id: "FIX",
            label: t("fix"),
            icon: <div className="reservation-state fix" />,
          },
          {
            id: "UNCONFIRMED",
            label: t("unconfirmed"),
            icon: <div className="reservation-state unconfirmed" />,
          },
          {
            id: "WAITING",
            label: t("waiting"),
            icon: <div className="reservation-state waiting" />,
          },

          // FIXME, zatím jen provizorní blbost, než bude v API
          {
            id: "ACCOMMODATED",
            label: "Ubytovaná",
            icon: <div className="reservation-state accommodated" />,
          },
          {
            id: "PARTIALLY_ACCOMMODATED",
            label: "Částečně ubytovaná",
            icon: <div className="reservation-state partially-accommodated" />,
          },
        ].map(i => [i.id, i]),
      ),
    [t],
  );

  const stateFilter: SelectFilterDef<ReservationState> = {
    items: Array.from(states.values()),
    selectAllLabel: t("all_reservation_states"),
    itemIdExtractor: i => i.id,
    renderValue: i => (
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <span>{i.icon}</span>
        <span>{i.label}</span>
      </div>
    ),
  };

  const isGroupFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "groups",
        label: "Skupiny",
      },
      {
        id: "individuals",
        label: "Jednotlivci",
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const tagFilter: SelectFilterDef<DummySelectItem> = {
    items: data.tagList?.map(t => ({ id: t._id, label: t.title })) ?? [],
    selectAllLabel: t("all_tags"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const roomTypeFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "sgl",
        label: "SGL",
      },
      {
        id: "dbs",
        label: "DBS",
      },
    ],
    selectAllLabel: t("all_types"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const sourceFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: 1,
        label: "Booking.com",
      },
      {
        id: 2,
        label: "Expedia",
      },
    ],
    selectAllLabel: t("all_sources"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const prepaymentFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "prof",
        label: "Vystavená proforma",
      },
      {
        id: "prof_after",
        label: "Proforma po splatnosti",
      },
    ],
    selectAllLabel: t("all_prepayments"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const roomingFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "full",
        label: "Vyplněný rooming",
      },
      {
        id: "partial",
        label: "Neúplný rooming",
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const guestCodeFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "business",
        label: "Business",
      },
      {
        id: "employee",
        label: "Zaměstnanec",
      },
    ],
    selectAllLabel: t("all_guest_codes"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const salesCodeFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "corporate",
        label: "Corporate",
      },
      {
        id: "walkin",
        label: "Walk-in",
      },
    ],
    selectAllLabel: t("all_sales_codes"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const nonRefundableFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "returnable",
        label: "Vratné",
      },
      {
        id: "non_returnable",
        label: "Nevratné",
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const authorFilter: SelectFilterDef<
    ReservationsQuery$data["userList"][number]
  > = {
    // NICETOHAVE jiný typ
    items: [...data.userList],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.userUid,
    renderValue: i => (
      <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <UserAvatar user={i} size="small" />
        {i.firstName + " " + i.lastName}
      </span>
    ),
  };

  const paymentMethodFilter: SelectFilterDef<DummySelectItem> = {
    items: [
      {
        id: "card",
        label: "Kartou",
      },
      {
        id: "invoice",
        label: "Faktura",
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const basicColumn = useCallback(
    <TSelectItem,>(
      key: keyof HighlightedReservationTableItem,
      title: string,
      filter?: SelectFilterDef<TSelectItem> | "date",
    ): ColumnDef<HighlightedReservationTableItem> => {
      return {
        id: key, // id:key mainly for filter to be able to obtain filtered column label
        accessorKey: key,
        enableColumnFilter: !!filter,
        filterFn: filter ? "arrIncludesSome" : undefined, // FIXME jinak pro datumy
        header: title,
        meta: {
          filter: filter,
        },
        // For highlighting to work
        cell: i => i.getValue(),
      };
    },
    [],
  );

  return [
    {
      id: "edit-column",
      enableHiding: false,
      enableSorting: false,
      cell: () => (
        <Pencil
          style={{ cursor: "pointer" }}
          onClick={e => e.stopPropagation()}
        />
      ),
    },
    {
      enableSorting: false,
      ...basicColumn("status", t("state"), stateFilter),
      cell: c => {
        const state = states.get(c.getValue() as string);
        return (
          state && (
            <div className="center">
              <Tooltipped tooltip={state.label}>{state.icon}</Tooltipped>
            </div>
          )
        );
      },
    },
    {
      enableSorting: false,
      ...basicColumn("group", t("is_group_abbr"), isGroupFilter),
      cell: i => i.getValue() === "GROUP" && <CheckIcon green />,
    },
    {
      ...basicColumn("tags", t("tags"), tagFilter),
      cell: i => {
        return (
          <div className="tags" style={{ display: "flex", gap: 6 }}>
            {(i.getValue() as number[]).map(tagId => {
              const tag = data.tagList?.find(t => t._id === tagId);
              return (
                // FIXME sjednotit s Tags / Tag které jsou nahoře v aktivních filtrech
                tag && (
                  <div
                    className="tag"
                    style={{
                      backgroundColor: tag.color ?? undefined,
                      color: "white", // TODO potom podle kontrastu
                      padding: "2px 6px",
                      margin: "-2px 0", // So it doesn't influence the rows height
                      borderRadius: 4,
                    }}>
                    {tag.title}
                  </div>
                )
              );
            })}
          </div>
        );
      },
    },
    {
      ...basicColumn("numberHighlighted", t("reservation_number_abbr")),
    },
    basicColumn("nameHighlighted", t("res_name")),
    basicColumn("roomType", t("room_type_abbr"), roomTypeFilter),
    basicColumn("roomCount", t("room_count")),
    basicColumn("guestsCount", t("people_count")),
    {
      ...basicColumn("checkIn", t("check_in"), "date"),
      cell: i =>
        i.getValue()
          ? // FIXME zobecnit dle vybrané lokalizace
            dayjs(i.getValue() as string).format("DD.MM.YYYY")
          : undefined,
    },
    {
      ...basicColumn("checkOut", t("check_out"), "date"),
      cell: i =>
        i.getValue()
          ? dayjs(i.getValue() as string).format("DD.MM.YYYY")
          : undefined,
    },
    basicColumn("source", t("source"), sourceFilter),
    basicColumn("price", t("price")),
    {
      enableSorting: false,
      ...basicColumn("prepayment", t("prepayment"), prepaymentFilter),
      cell: i => i.getValue(), // TODO ikona
    },
    {
      enableSorting: false,
      ...basicColumn("rooming", t("rooming"), roomingFilter),
      cell: i => {
        let icon: ReactNode;
        switch (i.getValue()) {
          case "FILLED":
            // TODO Ikona ve Figmě odpovídá něčemu mezi PersonCheck a PersonCheckFill
            icon = <PersonCheck style={{ color: "rgba(0, 172, 17, 1)" }} />;
            break;
          case "INCOMPLETE":
            icon = <PersonCheck />;
            break;
          default:
            return null;
        }

        return <div className="center">{icon}</div>;
      },
    },
    basicColumn("orderNumberHighlighted", t("order_number_abbr")),
    basicColumn("country", t("country")),
    {
      ...basicColumn("optionDate", t("option_date"), "date"),
      cell: i =>
        i.getValue()
          ? dayjs(i.getValue() as string).format("DD.MM.YYYY")
          : undefined,
    },
    basicColumn("guestCode", t("guest_code"), guestCodeFilter),
    basicColumn("salesCode", t("sales_code"), salesCodeFilter),
    basicColumn("note", t("note")),
    {
      ...basicColumn("nonRefundable", t("non_refundable"), nonRefundableFilter),
      cell: i => i.getValue() && <CheckIcon green />,
    },
    {
      ...basicColumn("author", t("author"), authorFilter),
      cell: cell => {
        const userUid = cell.row.original.author;
        const user = data.userList?.find(u => u.userUid === userUid);
        return user && <span>{user.firstName + " " + user.lastName}</span>;
      },
    },
    {
      ...basicColumn("createdAt", t("created"), "date"),
      cell: i =>
        i.getValue()
          ? dayjs(i.getValue() as string).format("DD.MM.YYYY")
          : undefined,
    },
    {
      ...basicColumn("cancelledAt", t("canceled"), "date"),
      cell: i => i.getValue() && <CheckIcon green />,
    },
    basicColumn("transactionNumber", t("transaction_number")),
    basicColumn("paymentMethod", t("payment_method_abbr"), paymentMethodFilter),
    basicColumn("attachment", t("attachments")),
  ];
}
