import "./yupConfig";
import "./styles/App.scss";
import { RouterProvider } from "react-router-dom";
import "./i18n/i18n";
import { router } from "./router/router";
import { HoresApp } from "h11-client-component-lib";

function H11App() {
  return (
    <HoresApp>
      <RouterProvider router={router} />
    </HoresApp>
  );
}

export default H11App;
