import { graphql } from "react-relay";

export const userInsertMutation = graphql`
  mutation UserInsertMutation($input: UserInsert!) {
    userCreate(user: $input) {
      user {
        userUid
      }
      ...PayloadMessages_interface
    }
  }
`;
