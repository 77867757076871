import { graphql } from "react-relay";

export const robotUserInsertMutation = graphql`
  mutation RobotUserInsertMutation($input: RobotUserInsert!) {
    robotUserCreate(user: $input) {
      user {
        userUid
      }
      token
      ...PayloadMessages_interface
    }
  }
`;
