/**
 * @generated SignedSource<<20c61d0dce0adc2715ef772386d4846a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
export type RolesQuery$variables = Record<PropertyKey, never>;
export type RolesQuery$data = {
  readonly roleList: ReadonlyArray<{
    readonly _id: number;
    readonly name: string;
    readonly systemRole: SystemRoles | null | undefined;
  }>;
  readonly systemRoleList: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
  }>;
};
export type RolesQuery = {
  response: RolesQuery$data;
  variables: RolesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRole",
    "kind": "LinkedField",
    "name": "roleList",
    "plural": true,
    "selections": [
      {
        "alias": "_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemRole",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SystemUserRole",
    "kind": "LinkedField",
    "name": "systemRoleList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RolesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "afc0f7aae854cf45764788ef3951f949",
    "id": null,
    "metadata": {},
    "name": "RolesQuery",
    "operationKind": "query",
    "text": "query RolesQuery {\n  roleList {\n    _id: id\n    name\n    systemRole\n  }\n  systemRoleList {\n    name\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2537da8bcabfa6123ecfdccbca70cac";

export default node;
