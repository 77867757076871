import { Page, Panel, Suspense } from "h11-client-component-lib";

function DashboardPanel() {
  return <Panel label="Title" variant="compact"></Panel>;
}

export function DashboardPage() {
  // noinspection HtmlUnknownTarget
  return (
    <Page variant="compact2" background="grey">
      <Suspense>
        <div
          style={{
            display: "grid",
            gap: 16,
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          }}>
          {[...Array(9)].map((_, index) => (
            <DashboardPanel key={index} />
          ))}
        </div>
      </Suspense>
    </Page>
  );
}
