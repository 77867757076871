/**
 * @generated SignedSource<<dd9459e57e744b6b00510dadcafd7520>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocalSessionQuery$variables = Record<PropertyKey, never>;
export type LocalSessionQuery$data = {
  readonly hotelChainHotelsList: ReadonlyArray<{
    readonly code: string;
    readonly hotelChainUid: string;
    readonly hotelName: string;
    readonly hotelUid: string;
  }>;
  readonly userCurrentGet: {
    readonly " $fragmentSpreads": FragmentRefs<"CurrentUserFragment">;
  } | null | undefined;
};
export type LocalSessionQuery = {
  response: LocalSessionQuery$data;
  variables: LocalSessionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userUid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "chainUser",
    "storageKey": null
  },
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lang",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hotelTel",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hotelEmail",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hotelSignature",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Asset",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Hotel",
  "kind": "LinkedField",
  "name": "hotelChainHotelsList",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hotelChainUid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hotelUid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hotelName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userCurrentGet",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "CurrentUserFragment",
            "selections": (v1/*: any*/),
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LocalSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userCurrentGet",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "05bff2e1474461ab2c8bfcf08ea772b2",
    "id": null,
    "metadata": {},
    "name": "LocalSessionQuery",
    "operationKind": "query",
    "text": "query LocalSessionQuery {\n  userCurrentGet {\n    ...CurrentUserFragment\n  }\n  hotelChainHotelsList {\n    code\n    hotelChainUid\n    hotelUid\n    hotelName\n  }\n}\n\nfragment CurrentUserFragment on User {\n  userUid\n  userName\n  firstName\n  lastName\n  chainUser\n  code\n  lang\n  hotelTel\n  hotelEmail\n  hotelSignature\n  avatar {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "84972cbdf2059a8eb3b2ebd86f713eae";

export default node;
