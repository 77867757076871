import { graphql } from "react-relay";

export const userRightsResourceFragment = graphql`
  fragment UserRightsResource_resource on UserRightsResource
  @relay(plural: true) {
    namespace
    resource
    title
    description
    actions {
      action
      title
      description
      systemRoles
    }
  }
`;
