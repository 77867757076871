import { useRouteError } from "react-router-dom";
import { LoginNeededError } from "@comm/relay";
import { useTranslation } from "react-i18next";
import ErrorIcon from "./error.svg";
import "./error.scss";
import { Button } from "h11-client-component-lib";
import { useOAuthLoginRedirect } from "@store";
import { useLogout } from "@comm/oAuth";
import { ReactNode } from "react";
import {
  UnknownHotelError,
  UnspecifiedHotelError,
} from "../../DomainHotelInfoContext";

// FIXME do knihovny, včetně ikonek
export function RouteErrorBoundary({
  additionalButtons,
}: {
  additionalButtons?: ReactNode;
}) {
  const { t } = useTranslation();
  const error = useRouteError();

  let showReload: boolean;
  let message: string;
  if (error instanceof UnspecifiedHotelError) {
    message = t("unspecified_hotel");
    showReload = false;
  } else if (error instanceof UnknownHotelError) {
    message = t("unknown_hotel");
    showReload = false;
  } else {
    message = t("unexpected_error_occurred");
    showReload = true;
  }

  return (
    <div className="error-page">
      <ErrorIcon />
      <span>{message}</span>
      {showReload && (
        <div style={{ display: "flex", gap: 32 }}>
          <Button
            variant="secondary"
            onClick={() => (window.location.href = "/")}>
            {t("reload")}
          </Button>
          {additionalButtons}
        </div>
      )}
    </div>
  );
}

export function MainRouteErrorBoundary() {
  const error = useRouteError();
  const oAuthLoginRedirect = useOAuthLoginRedirect();
  const logout = useLogout();
  const { t } = useTranslation();
  if (error instanceof LoginNeededError) {
    oAuthLoginRedirect();

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          fontSize: "2rem",
        }}>
        {t("redirecting_to_login_page")}
      </div>
    );
  } else {
    return (
      <RouteErrorBoundary
        additionalButtons={
          <Button variant="secondary" onClick={() => logout()}>
            {t("logout")}
          </Button>
        }
      />
    );
  }
}
