/**
 * @generated SignedSource<<434bb0cc24c379046fc916218681c36c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReservationSourcesQuery$variables = Record<PropertyKey, never>;
export type ReservationSourcesQuery$data = {
  readonly reservationSourceList: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: any;
  }>;
};
export type ReservationSourcesQuery = {
  response: ReservationSourcesQuery$data;
  variables: ReservationSourcesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReservationSource",
    "kind": "LinkedField",
    "name": "reservationSourceList",
    "plural": true,
    "selections": [
      {
        "alias": "_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationSourcesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReservationSourcesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "def4ee8dd6caf7cc732162be9e2325bb",
    "id": null,
    "metadata": {},
    "name": "ReservationSourcesQuery",
    "operationKind": "query",
    "text": "query ReservationSourcesQuery {\n  reservationSourceList {\n    _id: id\n    name\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "959e8c54344a613e564c20d6e100f88b";

export default node;
