import { useTranslation } from "react-i18next";
import "./index.scss";
import { RoleInsertDialog } from "./dialogs/RoleInsertDialog";
import { roleInsertDialogQuery } from "./graphql/RoleInsertDialogQuery";
import { roleUpdateDialogQuery } from "./graphql/RoleUpdateDialogQuery";
import { RoleUpdateDialog } from "./dialogs/RoleUpdateDialog";
import { rolesQuery } from "./graphql/RolesQuery";
import { roleDeleteMutation } from "./graphql/RoleDeleteMutation";
import { BasicEnumPage } from "../BasicEnumPage";
import { RolesTable } from "./RolesTable";

export function RolesPage() {
  const { t } = useTranslation();

  return (
    <BasicEnumPage
      listQuery={rolesQuery}
      updateParams={{
        dialogQuery: roleUpdateDialogQuery,
        DialogComponent: RoleUpdateDialog,
      }}
      insertParams={{
        dialogQuery: roleInsertDialogQuery,
        DialogComponent: RoleInsertDialog,
        newButtonLabel: t("create_new_role"),
      }}
      deleteParams={{
        mutation: roleDeleteMutation,
        successMessage: t("role_deleted"),
        questionBuilder: name => t("delete_role_question", { name: `${name}` }),
      }}
      label={t("roles_list")}
      searchPlaceholder={t("role_search_placeholder")}
      TableComponent={RolesTable}
      idField="_id"
    />
  );
}
