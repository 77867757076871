import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { RoleUpdateDialogQuery } from "@relay-generated/RoleUpdateDialogQuery.graphql";
import { roleUpdateDialogQuery } from "../graphql/RoleUpdateDialogQuery";
import { RoleBaseDialog } from "./RoleBaseDialog";
import { roleBaseSchema, SystemUserRole } from "../roleData";
import * as yup from "yup";
import { roleUpdateMutation } from "../graphql/RoleUpdateMutation";
import { RoleUpdateMutation } from "@relay-generated/RoleUpdateMutation.graphql";

export function RoleUpdateDialog({
  queryRef,
  ...restProps
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  queryRef: PreloadedQuery<RoleUpdateDialogQuery>;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(roleUpdateDialogQuery, queryRef);
  const existingRoleDetail = useMemo(() => data.roleGet!, [data]);

  // Přemapování aplikací a rolí na pole jejich ID
  const roleInput = useMemo(() => {
    const { _id, ...transitionedProps } = existingRoleDetail;
    return {
      id: _id,
      ...transitionedProps,
      applications: existingRoleDetail.applications.map(a => a._id),
    };
  }, [existingRoleDetail]);

  const schemaBuilder = useCallback(
    (systemRoles: readonly SystemUserRole[]) =>
      roleBaseSchema(systemRoles).shape({ id: yup.number().required() }),
    [],
  );

  return (
    <RoleBaseDialog<
      RoleUpdateDialogQuery,
      RoleUpdateMutation,
      ReturnType<typeof schemaBuilder>
    >
      {...restProps}
      title={t("role_update")}
      data={data}
      initialValues={roleInput}
      mutation={roleUpdateMutation}
      mutationVariablesExtractor={formValues => ({
        input: formValues,
      })}
      schemaBuilder={schemaBuilder}
    />
  );
}
