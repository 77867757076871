import { ResourcesTree } from "./ResourcesTree";
import { Suspense } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import {
  SearchField,
  SimpleTab,
  SimpleTabs,
  Switch,
  SwitchGroup,
  useFilter,
} from "h11-client-component-lib";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { RightsSettingsTabRolesQuery } from "@relay-generated/RightsSettingsTabRolesQuery.graphql";
import {
  ResourceRightsDetail,
  ResourceRightsInput,
} from "@shared/data/ResourceRightsData";

const applicationsQuery = graphql`
  query RightsSettingsTabRolesQuery {
    applicationListAvailable {
      _id: id
      code
      name
    }
  }
`;

export type FixedCustomResourceRight = ResourceRightsInput & {
  source: string | undefined;
};

export type FixedApplication = { id: number; source: string };

export interface RightsSettingsTabProps {
  resourcesDefinitions: ResourceRightsDetail[];
  fixedCustomResourceRights: FixedCustomResourceRight[];
  fixedApps: FixedApplication[];
}

// Počítá s tím, že pracuje s entitou, která má field "applications"
function Applications({ fixedApps }: { fixedApps: FixedApplication[] }) {
  const { t } = useTranslation();

  const {
    values: { applications: selectedApplications },
    setFieldValue,
  } = useFormikContext<{
    applications?: number[];
  }>();

  const applications = useLazyLoadQuery<RightsSettingsTabRolesQuery>(
    applicationsQuery,
    {},
  ).applicationListAvailable;

  return (
    <SwitchGroup>
      {applications.map(a => {
        const foundFixedApp = fixedApps.find(v => v.id === a._id);

        return (
          <Switch
            id={`app-${a._id}`}
            key={a._id}
            value={a._id}
            tooltip={
              foundFixedApp &&
              t("inherited_right", {
                source: foundFixedApp.source,
              })
            }
            checked={selectedApplications?.includes(a._id) || !!foundFixedApp}
            onChange={checked => {
              const newApps = [
                ...(selectedApplications?.filter(id => id !== a._id) ?? []),
              ];
              if (checked) {
                newApps.push(a._id);
              }
              setFieldValue("applications", newApps, true);
            }}
            // FIXME asi by to chtělo disable H11, aby si nepodřezal větev :)
            disabled={!!foundFixedApp}
            label={a.name}
          />
        );
      })}
    </SwitchGroup>
  );
}

export function RightsSettingsTab({
  resourcesDefinitions,
  fixedCustomResourceRights,
  fixedApps,
}: RightsSettingsTabProps) {
  const { t } = useTranslation();
  const [filter, setFilter, filterFunc] = useFilter();
  return (
    <SimpleTabs
      variant="secondary"
      style={{ flex: "1", minHeight: 0, height: "100%" }}>
      <SimpleTab title={t("allowed_permissions")}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            gap: 18,
            height: "100%",
          }}>
          <SearchField
            value={filter}
            onChange={setFilter}
            placeholder={t("insert_permission_name")}
          />
          <Suspense>
            <ResourcesTree
              filterValue={filter}
              filterFunc={filterFunc}
              fixedCustomResourceRights={fixedCustomResourceRights}
              resourcesDefinitions={resourcesDefinitions}
            />
          </Suspense>
        </div>
      </SimpleTab>
      <SimpleTab title={t("forbidden_rasters")}>TODO</SimpleTab>
      <SimpleTab title={t("allowed_reports")}>TODO</SimpleTab>
      <SimpleTab title={t("allowed_applications")}>
        <Applications fixedApps={fixedApps} />
      </SimpleTab>
    </SimpleTabs>
  );
}
