import { graphql } from "react-relay";

export const discountUpdateDialogQuery = graphql`
  query DiscountUpdateDialogQuery($id: Int!) {
    discountGet(discountId: $id) {
      _id: id
      name
      value
    }
  }
`;
