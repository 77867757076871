import {
  FormGrid,
  FormikNumberField,
  FormikTextField,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { DiscountFormData } from "./discountData";

export function DiscountForm() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}>
      <FormGrid style={{ flex: "1", minHeight: 0 }}>
        <FormikTextField<DiscountFormData> label={t("title")} field="name" />
        <FormikNumberField<DiscountFormData>
          label={t("discount_percent")}
          field="value"
        />
      </FormGrid>
    </div>
  );
}
