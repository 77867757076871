import { clsx } from "clsx";
import { BoxArrowInRight, XCircle } from "react-bootstrap-icons";
import { t } from "i18next";
import { Button } from "h11-client-component-lib";

export function TableContextFooter() {
  function onClick() {
    alert("TODO");
  }

  // TODO možná nějak zobecnit s FlowLayout a FlowGroup?
  return (
    <div className={clsx("actions")}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px 8px",
          justifyContent: "space-between",
        }}>
        <div
          style={{
            display: "flex",
            flexShrink: "1",
            gap: "20px 40px",
            justifyContent: "stretch",
            flexWrap: "wrap",
          }}>
          <div
            style={{
              display: "flex",
              flexShrink: "1",
              gap: 8,
              justifyContent: "stretch",
            }}>
            <Button
              variant="secondary"
              onClick={onClick}
              icon={<XCircle />}
              style={{}}>
              {t("storno")}
            </Button>
            <Button
              variant="secondary"
              onClick={onClick}
              icon={<BoxArrowInRight />}>
              {t("accommodate")}
            </Button>
          </div>
          <div
            style={{
              display: "grid",
              flexShrink: "1",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 8,
            }}>
            <Button variant="secondary" onClick={onClick}>
              {t("confirmations")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("prepayments")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("rooming")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("duplicate")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("proforma_invoices")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("history")}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            flexShrink: "1",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 8,
          }}>
          <Button variant="secondary" onClick={onClick}>
            {t("rooms_state")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("group_preview")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("names")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("group_cards_print")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("preview")}
          </Button>
        </div>
      </div>
    </div>
  );
}
