import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { useEffect, useMemo } from "react";
import { FormDialog } from "h11-client-component-lib";
import { roleBaseSchema, RoleFormData, SystemUserRole } from "../roleData";
import { RoleForm } from "../RoleForm";
import { RoleUpdateDialogQuery } from "@relay-generated/RoleUpdateDialogQuery.graphql";
import { RoleInsertDialogQuery } from "@relay-generated/RoleInsertDialogQuery.graphql";
import { useMergedResourcesDefinition } from "@shared/ui/resources/resourcesUtil";
import { useApiForm } from "@shared/forms/apiForm";
import { prepareApiCall } from "@comm/comm";
import { GraphQLTaggedNode } from "relay-runtime";
import { RoleUpdateMutation } from "@relay-generated/RoleUpdateMutation.graphql";
import { RoleInsertMutation } from "@relay-generated/RoleInsertMutation.graphql";
import { InferType } from "yup";

export function RoleBaseDialog<
  TQuery extends RoleUpdateDialogQuery | RoleInsertDialogQuery,
  TMutation extends RoleUpdateMutation | RoleInsertMutation,
  TSchema extends ReturnType<typeof roleBaseSchema>,
>({
  open,
  onClose,
  data,
  schemaBuilder,
  initialValues,
  title,
  mutation,
  mutationVariablesExtractor,
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  data: TQuery["response"];
  schemaBuilder: (systemRoles: readonly SystemUserRole[]) => TSchema;
  initialValues: RoleFormData;
  title: string;
  mutation: GraphQLTaggedNode;
  mutationVariablesExtractor: (
    formValues: InferType<TSchema>,
  ) => TMutation["variables"];
}) {
  const { t } = useTranslation();

  const systemRoles = useMemo(() => data.systemRoleList!, [data]);
  const resourcesDefinitions = useMergedResourcesDefinition(data);

  const schema = useMemo(() => schemaBuilder(systemRoles), [systemRoles]);

  const { formik } = useApiForm<RoleFormData, TSchema, never>({
    initialValues,
    schema,
    messages: {
      success: t("role_saved"),
    },
    onSubmit: (values, relayEnvironment) => {
      return prepareApiCall<TMutation, never>({
        mutation: mutation,
        variables: mutationVariablesExtractor(values),
        environment: relayEnvironment,
      }).execute();
    },
    visible: open,
    onSuccess: () => {
      onClose(true);
    },
  });

  console.log(formik.errors);

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues(initialValues, true);
    }
  }, [open]);

  return (
    <FormikProvider value={formik}>
      <FormDialog open={open} onClose={() => onClose(false)} title={title}>
        <RoleForm
          systemRoles={systemRoles}
          resourcesDefinitions={resourcesDefinitions}
        />
      </FormDialog>
    </FormikProvider>
  );
}
