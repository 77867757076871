import { UserFormData } from "../userData";
import {
  Button,
  FormGrid,
  FormikPhotoDropZone,
  FormikTextField,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { NotificationsGroupsPanel } from "./NotificationsGroupsPanel";

export function UserProfileForm() {
  const { t } = useTranslation();
  return (
    <FormGrid
      gridTemplateColumns="repeat(4, minmax(100px, 1fr))"
      gridTemplateRows="auto 1fr">
      <FormikTextField label={t("hotel_phone")} field="hotelTel" />
      <div
        style={{
          gridColumn: 3,
          gridRow: "1 / 3",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <FormikPhotoDropZone<UserFormData> field="avatarFile" />
      </div>
      <div style={{ gridColumn: 4, gridRow: "1 / 3" }}>
        <NotificationsGroupsPanel />
      </div>
      <FormikTextField
        label={t("hotel_email")}
        field="hotelEmail"
        style={{ gridColumn: "1 / 3" }}
      />
      <FormikTextField
        label={t("insert_signature")}
        field="hotelSignature"
        multiline={3}
        placeholder={t("insert_signature_placeholder")}
        style={{ gridColumn: "1 / 3" }}
      />
      <Button variant="secondary" style={{ gridColumn: "1 / 2" }}>
        {t("insert_signature_image")}
      </Button>
    </FormGrid>
  );
}
