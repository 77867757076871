import { graphql } from "react-relay";

export const userSetAvatarMutation = graphql`
  mutation UserSetAvatarMutation(
    $userUid: UUID!
    $fileUpload: FileUploadRequest!
  ) {
    userSetAvatar(userUid: $userUid, fileUpload: $fileUpload) {
      fileUploadResponse {
        uploadUrl
      }
      ...PayloadMessages_interface
    }
  }
`;
