import { graphql } from "react-relay";

// FIXME DUMMY
export const reservationSourceInsertDialogQuery = graphql`
  query ReservationSourceInsertDialogQuery {
    reservationsOverviewList(filters: {}) {
      pageInfo {
        startCursor
      }
    }
  }
`;
