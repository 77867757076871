import { graphql } from "react-relay";

export const userDeleteOwnAvatarMutation = graphql`
  mutation UserDeleteOwnAvatarMutation {
    userDeleteOwnAvatar {
      deleted
      ...PayloadMessages_interface
    }
  }
`;
