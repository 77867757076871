import { graphql } from "react-relay";

// TODO stjednotit pole uživatele s currentUserQuery do jednoho
export const ownProfileUpdateMutation = graphql`
  mutation OwnProfileUpdateMutation($input: UserProfileOwnUpdate!) {
    userProfileOwnUpdate(user: $input) {
      user {
        ...CurrentUserFragment
      }
      ...PayloadMessages_interface
    }
  }
`;
