/**
 * @generated SignedSource<<2fbc461696288f4514e8d16bfbdf8b4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReservationSourceInsertDialogQuery$variables = Record<PropertyKey, never>;
export type ReservationSourceInsertDialogQuery$data = {
  readonly reservationsOverviewList: {
    readonly pageInfo: {
      readonly startCursor: string | null | undefined;
    };
  };
};
export type ReservationSourceInsertDialogQuery = {
  response: ReservationSourceInsertDialogQuery$data;
  variables: ReservationSourceInsertDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "filters",
        "value": {}
      }
    ],
    "concreteType": "ReservationsOverviewHoresConnection",
    "kind": "LinkedField",
    "name": "reservationsOverviewList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "reservationsOverviewList(filters:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationSourceInsertDialogQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReservationSourceInsertDialogQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ce533143f36597ce6bff195c99975d98",
    "id": null,
    "metadata": {},
    "name": "ReservationSourceInsertDialogQuery",
    "operationKind": "query",
    "text": "query ReservationSourceInsertDialogQuery {\n  reservationsOverviewList(filters: {}) {\n    pageInfo {\n      startCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "10979110d108d4f41fa1e3f334ffd31e";

export default node;
