/**
 * @generated SignedSource<<b8679850409c0ca1895dfa0f8e3e1a76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
export type RoleUpdateDialogQuery$variables = {
  id: number;
};
export type RoleUpdateDialogQuery$data = {
  readonly fileStoreResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly hotelsResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly reservationResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly roleGet: {
    readonly _id: number;
    readonly applications: ReadonlyArray<{
      readonly _id: number;
    }>;
    readonly customResourceRights: ReadonlyArray<{
      readonly action: string;
      readonly namespace: string;
      readonly resource: string;
    }>;
    readonly description: string | null | undefined;
    readonly name: string;
    readonly systemRole: SystemRoles | null | undefined;
  } | null | undefined;
  readonly systemRoleList: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
  }>;
  readonly usersResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
};
export type RoleUpdateDialogQuery = {
  response: RoleUpdateDialogQuery$data;
  variables: RoleUpdateDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resource",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "roleId",
      "variableName": "id"
    }
  ],
  "concreteType": "UserRole",
  "kind": "LinkedField",
  "name": "roleGet",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "applications",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemRole",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceRights",
      "kind": "LinkedField",
      "name": "customResourceRights",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SystemUserRole",
  "kind": "LinkedField",
  "name": "systemRoleList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserRightsResource_resource"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  (v5/*: any*/),
  (v10/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRightsAction",
    "kind": "LinkedField",
    "name": "actions",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      (v10/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemRoles",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleUpdateDialogQuery",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "hotelsResourcesDefinition",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "fileStoreResourcesDefinition",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "usersResourcesDefinition",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "reservationResourcesDefinition",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleUpdateDialogQuery",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "hotelsResourcesDefinition",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "fileStoreResourcesDefinition",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "usersResourcesDefinition",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "reservationResourcesDefinition",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b39a1d3b000df0e7bebe7404bab95a20",
    "id": null,
    "metadata": {},
    "name": "RoleUpdateDialogQuery",
    "operationKind": "query",
    "text": "query RoleUpdateDialogQuery(\n  $id: Int!\n) {\n  roleGet(roleId: $id) {\n    _id: id\n    name\n    applications {\n      _id: id\n    }\n    systemRole\n    description\n    customResourceRights {\n      action\n      resource\n      namespace\n    }\n  }\n  systemRoleList {\n    code\n    name\n  }\n  hotelsResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  fileStoreResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  usersResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  reservationResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n}\n\nfragment UserRightsResource_resource on UserRightsResource {\n  namespace\n  resource\n  title\n  description\n  actions {\n    action\n    title\n    description\n    systemRoles\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ba2e234405760505c92bf573e03c942";

export default node;
