import { graphql } from "react-relay";

export const userUpdateMutation = graphql`
  mutation UserUpdateMutation($input: UserUpdate!) {
    userUpdate(user: $input) {
      user {
        userUid
      }
      ...PayloadMessages_interface
    }
  }
`;
