import { useTranslation } from "react-i18next";
import { discountBaseSchema } from "../discountData";
import { DiscountBaseDialog } from "./DiscountBaseDialog";

export function DiscountInsertDialog({
  ...restProps
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <DiscountBaseDialog
      {...restProps}
      title={t("discount_insert")}
      initialValues={{}}
      schema={discountBaseSchema}
    />
  );
}
