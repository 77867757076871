import { useTranslation } from "react-i18next";
import { BasicEnumPage } from "../BasicEnumPage";
import { discountsQuery } from "./graphql/DiscountsQuery";
import { DiscountsTable } from "./DiscountsTable";
import { discountUpdateDialogQuery } from "./graphql/DiscountUpdateDialogQuery";
import { DiscountUpdateDialog } from "./dialogs/DiscountUpdateDialog";
import { DiscountInsertDialog } from "./dialogs/DiscountInsertDialog";

export function DiscountsPage() {
  const { t } = useTranslation();

  return (
    <BasicEnumPage
      listQuery={discountsQuery}
      label={t("discount_list")}
      searchPlaceholder={t("discount_search_placeholder")}
      TableComponent={DiscountsTable}
      updateParams={{
        dialogQuery: discountUpdateDialogQuery,
        DialogComponent: DiscountUpdateDialog,
      }}
      insertParams={{
        DialogComponent: DiscountInsertDialog,
        newButtonLabel: t("create_new_discount"),
      }}
      idField="_id"
    />
  );
}
