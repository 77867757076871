import { useTranslation } from "react-i18next";
import { UserAccountForm } from "./UserAccountForm";
import { UserProfileForm } from "./UserProfileForm";
import { UserRightsForm } from "./UserRightsForm";
import { SimpleTab, SimpleTabs } from "h11-client-component-lib";
import { RobotUserAccountForm } from "./RobotUserAccountForm";

export function UserForm({ robotUser = false }: { robotUser?: boolean }) {
  const { t } = useTranslation();
  return (
    <SimpleTabs style={{ minHeight: 300, flex: "1" }}>
      <SimpleTab title={t("account")}>
        {robotUser ? <RobotUserAccountForm /> : <UserAccountForm />}
      </SimpleTab>
      <SimpleTab title={t("profile")}>
        <UserProfileForm />
      </SimpleTab>
      <SimpleTab title={t("user_rights")}>
        <UserRightsForm />
      </SimpleTab>
    </SimpleTabs>
  );
}
