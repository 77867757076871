import { graphql } from "react-relay";

export const rolesQuery = graphql`
  query RolesQuery {
    roleList {
      _id: id
      name
      systemRole
    }
    systemRoleList {
      name
      code
    }
  }
`;
