import { graphql } from "react-relay";

// FIXME userList a reservationSourcesList dát jinam - je na to článek v doc relay ohledně toho, aŤ argumenty ovlivňují jen část celého dotazu
export const reservationsQuery = graphql`
  query ReservationsQuery(
    $filters: ReservationsOverviewFilters
    $orderBy: ReservationsOverviewOrderBy
  ) {
    userList {
      userUid
      firstName
      lastName
      avatar {
        url
      }
    }
    reservationSourceList {
      _id: id
      name
    }
    tagList {
      _id: id
      title
      color
    }
    reservationsOverviewList(
      filters: { filters: $filters, orderBy: $orderBy }
    ) {
      edges {
        cursor
        node {
          id
          status
          group
          tags
          number
          name
          roomType
          roomCount
          guestsCount
          checkIn
          checkOut
          source
          price
          prepayment
          rooming
          orderNumber
          country
          optionDate
          guestCode
          salesCode
          note
          nonRefundable
          author
          createdAt
          cancelledAt
          transactionNumber
          paymentMethod
          attachment
        }
      }
    }
  }
`;
