import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { discountBaseSchema } from "../discountData";
import { DiscountBaseDialog } from "./DiscountBaseDialog";
import * as yup from "yup";
import { discountUpdateDialogQuery } from "../graphql/DiscountUpdateDialogQuery";
import { DiscountUpdateDialogQuery } from "@relay-generated/DiscountUpdateDialogQuery.graphql";
import { useMemo } from "react";

const discountUpdateSchema = discountBaseSchema.clone().shape({
  id: yup.number().required(),
});

export function DiscountUpdateDialog({
  queryRef,
  ...restProps
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  queryRef: PreloadedQuery<DiscountUpdateDialogQuery>;
}) {
  const { t } = useTranslation();

  const existingItem = usePreloadedQuery<DiscountUpdateDialogQuery>(
    discountUpdateDialogQuery,
    queryRef,
  ).discountGet!;

  const itemInput = useMemo(() => {
    const { _id, ...transitionedProps } = existingItem;
    return {
      id: _id,
      ...transitionedProps,
    };
  }, [existingItem]);

  return (
    <DiscountBaseDialog
      {...restProps}
      title={t("discount_update")}
      initialValues={itemInput}
      schema={discountUpdateSchema}
    />
  );
}
