import * as yup from "yup";
import { ReservationSourcesQuery } from "@relay-generated/ReservationSourcesQuery.graphql";
import { ReservationSourceUpdate } from "@relay-generated/ReservationSourceUpdateMutation.graphql";
import { ReservationSourceInsert } from "@relay-generated/ReservationSourceInsertMutation.graphql";

export type ReservationSourceTableItem =
  ReservationSourcesQuery["response"]["reservationSourceList"][number];
export type ReservationSourceFormData = Partial<
  ReservationSourceUpdate & ReservationSourceInsert
>;

export const reservationSourceBaseSchema = yup.object({
  name: yup.string().required(),
  code: yup.string().required(),
  // FIXME
});
