import { BFF_URI } from "@params";
import {
  TableColumnPreference,
  UserPreferencesContext,
} from "h11-client-component-lib";
import { ReactNode } from "react";

export function H11UserPreferencesContext({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <UserPreferencesContext.Provider
      value={{
        saveTablePreferences: async (
          tableId: string,
          columnPreferences: TableColumnPreference[],
        ) => {
          // FIXME zapojit tanstack query
          await fetch(`${BFF_URI}/tablePreferences/${tableId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              columnPreferences.map(({ columnId, visible, sortOrder }) => ({
                columnId,
                visible,
                sortOrder,
              })),
            ),
          });
        },
        loadTablePreferences: async (tableId: string) => {
          // FIXME zapojit tanstack query
          return await fetch(`${BFF_URI}/tablePreferences/${tableId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }).then(async res => {
            if (res.status === 404) {
              return null;
            }

            return (await res.json()) as TableColumnPreference[];
          });
        },
      }}>
      {children}
    </UserPreferencesContext.Provider>
  );
}
