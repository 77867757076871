import { graphql } from "react-relay";

export const userQuery = graphql`
  query UserQuery($id: UUID!) {
    userGet(userUid: $id) {
      userUid
      userName
      validTo
      chainUser
      firstName
      lastName
      code
      tel
      email
      lang
      note
      interactiveLogin
      userRoles {
        _id: id
      }
      hotelTel
      hotelEmail
      hotelSignature
      customResourceRights {
        namespace
        resource
        action
      }
      avatar {
        url
      }
      applications {
        _id: id
      }
    }
  }
`;
