import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { roleBaseSchema } from "../roleData";
import { roleInsertDialogQuery } from "../graphql/RoleInsertDialogQuery";
import { RoleInsertDialogQuery } from "@relay-generated/RoleInsertDialogQuery.graphql";
import { RoleBaseDialog } from "./RoleBaseDialog";
import { roleInsertMutation } from "../graphql/RoleInsertMutation";

export function RoleInsertDialog({
  queryRef,
  ...restProps
}: {
  open: boolean;
  onClose: (submitted: boolean) => void;
  queryRef: PreloadedQuery<RoleInsertDialogQuery>;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(roleInsertDialogQuery, queryRef);

  return (
    <RoleBaseDialog
      {...restProps}
      title={t("role_insert")}
      data={data}
      initialValues={{}}
      schemaBuilder={systemRoles => roleBaseSchema(systemRoles)}
      mutation={roleInsertMutation}
      mutationVariablesExtractor={formValues => ({
        input: formValues,
      })}
    />
  );
}
