import { graphql } from "react-relay";

export const reservationSourcesQuery = graphql`
  query ReservationSourcesQuery {
    reservationSourceList {
      _id: id
      name
      code
    }
  }
`;
