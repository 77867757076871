export const H11_URI = window.location.origin; // Nahrazené v runtime
export const BFF_URI = `${H11_URI}/bff`;
export const HOTELLESS_DOMAIN = (() => {
  const { host } = window.location;
  if (host.endsWith("hores.localhost")) {
    return "hores.localhost";
  } else if (host.endsWith("h11.horesplus.com")) {
    return "h11.horesplus.com";
  } else if (host.endsWith("h11.almondiero.com")) {
    return "h11.almondiero.com";
  } else {
    throw new Error("Unknown domain " + host);
  }
})();

export const OAUTH_REDIRECT_URL = `https://${HOTELLESS_DOMAIN}/login/callback`;

export const DEV = window.location.host.endsWith("localhost");
