import { Table } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";

export function PriceListTable() {
  const { t } = useTranslation();
  return (
    <Table
      customizableColumns={false}
      columns={[
        {
          accessorKey: "name",
          header: t("title"),
        },
        {
          accessorKey: "discount",
          header: t("discount"),
        },
      ]}
      data={[
        {
          name: "Normální v EUR",
          discount: "Za tři noci",
        },
        {
          name: "Normální v CZK",
          discount: "Za tři noci",
        },
        {
          name: "B&B",
          discount: "5 %",
        },
      ]}
      fullWidth
    />
  );
}
