import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import { BFF_URI } from "@params";
import { useTranslation } from "react-i18next";
import { Loading } from "h11-client-component-lib";

// TODO nebude "snažší" mít redirect přímo do BFF?
export function OAuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const code = useMemo(() => searchParams.get("code"), [searchParams]);

  /* Pozor react rerenderuje a zároveň volá useEffect 2x i když se nezměnili závislosti,
     pokud jsem ve StrictMode a development! Proto
  */
  const callbackSent = useRef(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (!callbackSent.current) {
      callbackSent.current = true;
      fetch(`${BFF_URI}/oauth/callback`, {
        redirect: "follow",
        method: "POST",
        credentials: "same-origin",
        body: code,
      })
        .then(res => {
          if (res.ok) {
            // Timeout so that cookies can be set... weird, but it looks it's necessary
            setTimeout(() => {
              const searchParams = new URLSearchParams(window.location.search);
              let initialUrl: string | undefined;
              try {
                initialUrl = JSON.parse(
                  searchParams.get("state") as string,
                ).initialUrl;
              } catch (e) {
                // no-op
              }

              if (initialUrl) {
                window.location.href = initialUrl;
              } else {
                navigate("/");
              }
            }, 1000);
          } else {
            throw Error();
          }
        })
        .catch(() => {
          // FIXME jinak! Tady nemám domainHotelInfo, protože jsem na doméně bez hotelu
          //oAuthLoginRedirect();
        });
    }
  }, [code]);

  return <Loading text={t("redirecting_to_app")} centerVertical />;
}
