import { graphql } from "react-relay";

export const roleUpdateDialogQuery = graphql`
  query RoleUpdateDialogQuery($id: Int!) {
    roleGet(roleId: $id) {
      _id: id
      name
      applications {
        _id: id
      }
      systemRole
      description
      customResourceRights {
        action
        resource
        namespace
      }
    }

    systemRoleList {
      code
      name
    }

    hotelsResourcesDefinition {
      ...UserRightsResource_resource
    }
    fileStoreResourcesDefinition {
      ...UserRightsResource_resource
    }
    usersResourcesDefinition {
      ...UserRightsResource_resource
    }
    reservationResourcesDefinition {
      ...UserRightsResource_resource
    }
  }
`;
