import { languages } from "h11-client-component-lib";
import * as yup from "yup";
import { UserUpdate } from "@relay-generated/UserUpdateMutation.graphql";
import { UserInsert } from "@relay-generated/UserInsertMutation.graphql";
import { UserRightsFormDataQuery } from "@relay-generated/UserRightsFormDataQuery.graphql";
import { UsersQuery } from "@relay-generated/UsersQuery.graphql";
import { UserProfileOwnUpdate } from "@relay-generated/OwnProfileUpdateMutation.graphql";
import { ChangeOwnPasswordInsert } from "@relay-generated/UserChangeOwnPasswordMutation.graphql";
import { RobotUserInsert } from "@relay-generated/RobotUserInsertMutation.graphql";

type WithAvatar = {
  avatarFile?: string | File;
};

export type UserTableItem = UsersQuery["response"]["userList"][number];
export type UserFormData = Partial<
  UserUpdate & UserInsert & RobotUserInsert & WithAvatar
>;

export type OwnProfileFormData = UserProfileOwnUpdate & WithAvatar;

export type AssignedRoleDetail =
  UserRightsFormDataQuery["response"]["roleList"][number];

export type OwnPasswordChangeFormData = Partial<
  NonNullable<ChangeOwnPasswordInsert> & {
    newPasswordAgain: string;
  }
>;

export const userProfileBaseSchema = yup.object({
  code: yup.string().max(4).required(),
  hotelEmail: yup.string().email().nullable().default(""),
  firstName: yup.string().required(),
  lang: yup.string().required().oneOf(languages),
  hotelTel: yup.string().nullable().default(""),
  lastName: yup.string().required(),
  hotelSignature: yup.string().nullable().default(""),

  // Only File, not string, because for update I only want to allow new File
  avatarFile: yup.mixed<File>().optional(),
});

export const customResourceRightsSchema = yup.array().of(
  yup.object({
    namespace: yup.string().required(),
    resource: yup.string().required(),
    action: yup.string().required(),
  }),
);

export const userBaseSchema = userProfileBaseSchema.clone().shape({
  tel: yup.string().nullable().default(""),
  email: yup.string().email().nullable().default(""),
  applications: yup.array().of(yup.number().required()),
  chainUser: yup.boolean(),
  validTo: yup.string().required().toApiDate(),
  userName: yup.string().required(),
  userRoles: yup.array().of(yup.number().required()),
  note: yup.string().nullable().default(""),
  customResourceRights: customResourceRightsSchema,
});

export const ownPasswordChangeSchema = yup.object({
  oldPassword: yup.string().required(),
  // FIXME minimální požadavky na heslo
  newPassword: yup.string().required(),
  newPasswordAgain: yup
    .string()
    .required()
    .oneOf([yup.ref("newPassword")]),
});
